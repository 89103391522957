import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
import mealReducer from "./../state/meals/mealSlice"
import userReducer from "./../state/user/userSlice"
import clientsReducer from "./../state/clients/clientsSlice"

export const store = configureStore({
  reducer: {
    meal: mealReducer,
    user: userReducer,
    client: clientsReducer
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
