import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { MealControllerService, User, UserControllerService } from "../..";
import { RootState } from "../../app/store";


interface ClientsState {
    clients: User[],
    currentClient?: User
}

const initialState: ClientsState = {
    clients: []
}

const clientsSlice = createSlice({
    name: "clients",
    initialState,
    reducers: {
        setCurrentClient: (state, action) => {
            state.currentClient = state.clients.find(c => c.id == action.payload.id)
         }
    },
    extraReducers(builder) {
        builder.addCase(fetchTrainees.fulfilled, (state, action) => {
            state.clients = action.payload
        })
        .addCase(addTrainee.fulfilled, (state, action) => {
            state.clients.push(action.payload)
        })
        .addCase(getClientMeals.fulfilled, (state, action) => {
            state.currentClient!!.meals = action.payload
        })
        .addCase(deleteClientMeal.fulfilled, (state, action) => {
            state.currentClient!!.meals = state.currentClient?.meals?.filter(m => m.id != action.meta.arg)
        })        
    }
})

export const fetchTrainees = createAsyncThunk('clients/fetchTrainees', async () => {
    const response = await UserControllerService.getTrainees()
    return response
})

export const addTrainee = createAsyncThunk('clients/addTrainee', async (user: User) => {
    const response = await UserControllerService.createUser(user)
    return response
})

export const getClientMeals = createAsyncThunk('clients/getClientMeals', async (clientId: string) => {
    const response = await MealControllerService.getAllMealsForClient(clientId)
    return response
})

export const deleteClientMeal = createAsyncThunk('/clients/deleteClientMeal', async (mealId: string) => {
    const response = await MealControllerService.deleteMeal(mealId)
    return response
})

export const { setCurrentClient } = clientsSlice.actions

export const selectClients = (state: RootState) => state.meal

export default clientsSlice.reducer
