/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BodyMeasurements } from './BodyMeasurements';
import type { Meal } from './Meal';

export type User = {
    id?: string;
    username?: string;
    password?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    sex?: User.sex;
    birthdate?: string;
    roles?: Array<string>;
    trainer?: User;
    bodyMeasurements?: Array<BodyMeasurements>;
    meals?: Array<Meal>;
    mealtimeIntake?: Record<string, Record<string, number>>;
    physicalActivityLevel?: number;
    nutrientsRatio?: Record<string, number>;
    premiumUntil?: string;
    languageCodes?: Array<'EN' | 'CS' | 'SK'>;
    dietPreference?: string;
    allergies?: Array<string>;
    timezone?: string;
};

export namespace User {

    export enum sex {
        MALE = 'MALE',
        FEMALE = 'FEMALE',
    }


}

