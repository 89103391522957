import { Box } from "@mui/material";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { NewClient } from "./NewClient";
import { onboardUser, updateTimezone } from "../../state/user/userSlice";

export function Onboarding() {
    const { coupon } = useParams()
    let user = useSelector((state: RootState) => state.user)
    const dispatch = useDispatch<any>()

    const submissionHandler = () => {
        dispatch(onboardUser({
            coupon: coupon,
            user: user.newUser
        }))
    }

    return (
        <Box>
            {coupon &&
                <NewClient coupon={coupon} submitHandler={submissionHandler}></NewClient>
            }
        </Box>
    )
}