import { AlertProps, Box, Snackbar, Typography } from "@mui/material"
import MuiAlert from '@mui/material/Alert';
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { RootState } from "../../app/store";
import React from "react";
import { AddFoodstuff } from "./AddFoodstuff";
import { AddMeal } from "./AddMeal";
import { fetchFoodstuffs, fetchMeals } from "../../state/meals/mealSlice";

export function Meals() {
  let foodstuffStatus = useSelector((state: RootState) => state.meal.foodstuff.status)
  const [snackbarOpen, setSnackbarOpen] = useState(false)

  const dispatch = useDispatch<any>()

  let foodstuffs = useSelector((state: RootState) => state.meal.foodstuffs.foodstuffs)
  let meals = useSelector((state: RootState) => state.meal.meals)

  useEffect(() => {
    if (foodstuffStatus == 'OK') {
      setSnackbarOpen(true)
    }
    dispatch(fetchFoodstuffs())
    dispatch(fetchMeals())
  }, [foodstuffStatus])


  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });



  const handleClose = () => {
    setSnackbarOpen(false)
  };


  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'center'
    }}>
      <AddFoodstuff />
      <AddMeal />
      <Snackbar open={snackbarOpen} onClose={handleClose} autoHideDuration={6000}>
        <Alert severity="success" sx={{ width: '100%' }} onClose={handleClose}>
          Potravina uložená
        </Alert>
      </Snackbar>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly'
      }}>
        <Box>
          <Typography variant="h4" alignItems={'center'}>Zoznam potravín</Typography>
          {foodstuffs.map(fs => {
            return (<Box key={fs.id}>
              <Typography m={1}>{fs.name}</Typography>
            </Box>)
          })}
        </Box>
        <Box>
          <Typography variant="h4" alignItems={'center'}>Zoznam jedel</Typography>
          {meals.map((meal,i) => {
            return(<Box key={i}>
              <Typography m={1}>{meal.name}</Typography>
            </Box>)
          })}
        </Box>
      </Box>
    </Box>
  )
}
