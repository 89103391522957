import { Box, Typography } from "@mui/material";
import { recommendMeals } from "../../state/meals/mealSlice";
import { useEffect } from "react";
import './consumedMeal.css'
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";


export function RecommendedMeals() {

  const dispatch = useDispatch<any>()

  let recommendedMeals = useSelector((state: RootState) => state.meal.recommendedMeals)

  useEffect(() => {
    dispatch(recommendMeals())
  }, [])

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    }}>
      <Typography variant="h3">Doporučená jídla</Typography>
      {recommendedMeals.map(m => {
        return(<p>{m.name}</p>)
      })}
    </Box >
  )
}

