import { Box } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../app/store"
import { useEffect } from "react"
import { getUser, updateTimezone } from "../../state/user/userSlice"
import { ClientDashboard } from "../dashboard/ClientDashboard"
import { TrainerDashboard } from "../dashboard/TrainerDashboard"
import { fetchMeals } from "../../state/meals/mealSlice"


export function Home() {

  const user = useSelector((state: RootState) => state.user)
  const dispatch = useDispatch<any>()

  useEffect(() => {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    dispatch(updateTimezone(timeZone))
    dispatch(getUser())
    dispatch(fetchMeals())
  } ,[])

  return (
    
    <Box>
      {user.user?.roles?.includes('TRAINER') ? <TrainerDashboard /> : <ClientDashboard />}
    </Box>
  )
}
