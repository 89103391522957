import { Avatar, Box, Button, Container, TextField, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../app/store"
import { login } from "../../state/user/userSlice"
import { useNavigate } from 'react-router-dom'
import { useEffect } from "react";


export function Login() {

    const navigate = useNavigate();

    let user = useSelector((state: RootState) => state.user)

    useEffect(() => {
        if (user.user?.id != null) {
            navigate(`/user/${user.user.id}/home`)
        }
    }, [user])


    const dispatch = useDispatch<any>()

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        dispatch(login({
            username: data.get('username')?.toString(),
            password: data.get('password')?.toString()
        }))
    };

    return (

        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }} />

                <Typography component="h1" variant="h5">
                    Prihlásiť sa
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Username"
                        name="username"
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Sign In
                    </Button>
                </Box>
            </Box>
        </Container>
    )
}
