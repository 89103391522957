const ColorService = {

    getColor: (mealtime: string) => {
        let color: string
        switch (mealtime) {
            case 'BREAKFAST':
                color = "#FFD700"
                break
            case 'SNACK_AM':
                color = "#87CEEB"
                break
            case 'LUNCH':
                color = "#00CED1"
                break
            case 'SNACK_PM':
                color = "#FFB6C1"
                break
            case 'DINNER':
                color = "#8A2BE2"
                break
            default:
                color = "#00000"
        }
        return color
    }
} 

export default ColorService