import Box from "@mui/material/Box";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getClientMeals, setCurrentClient } from "../../state/clients/clientsSlice";
import { RootState } from "../../app/store";
import { Typography } from "@mui/material";
import { getUserById } from "../../state/user/userSlice";
import { DietCalculation } from "../diet/DietCalculation";

export function Trainee() {

    const dispatch = useDispatch<any>()

    let client = useSelector((state: RootState) => state.client)

    const { traineeId } = useParams()

    useEffect(() => {
        dispatch(setCurrentClient({
            id: traineeId
        }))
        dispatch(getClientMeals(traineeId!!))
        dispatch(getUserById(traineeId!!))
    }, [])

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Typography variant="h2">{client.currentClient?.username}</Typography>
            <DietCalculation />
        </Box>
    )
}
