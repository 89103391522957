import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            light: '#7F5539',
            main: '#7F5539',
            dark: '#000',
            contrastText: '#EDE0D4',
        },
        secondary: {
            light: '#000',
            main: '#000',
            dark: '#000',
            contrastText: '#000',
        },
        warning: {
            light: '#000',
            main: '#000',
            dark: '#000',
            contrastText: '#000'
        },
        error: {
            light: '#000',
            main: '#7F5539',
            dark: '#000',
            contrastText: '#EDE0D4',
        }
    },
})

export default theme