// import valkyra from "/home/koncept/diet-web/src/public/valkyra.png"

import { Autocomplete, Box, Button, Card, CardContent, Input, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import React, { } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from '@mui/icons-material/Delete'
import { addFoodstuffToMeal, getLikeFoodstuffs, saveMeal, updateMealFoodstuffWeight, patchMeal, setLikeFoodstuffs, deleteMealFoodstuff, updateMealFoodstuffValue } from "../../state/meals/mealSlice";
import { RootState } from "../../app/store";
import { MeasurementUnits } from "./MeasurementUnits";
import { Foodstuff } from "../../models/Foodstuff";


export function AddMeal() {

  let meal = useSelector((state: RootState) => state.meal)

  const dispatch = useDispatch<any>()

  const updateFoodstuffValueHandler = (id: string, value: string) => {
    dispatch(updateMealFoodstuffValue({ id: id, value: value }))
  }

  function saveMealHandler() {
    dispatch(saveMeal(meal.meal))
  }

  function updateMealNameHandler(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
    dispatch(patchMeal({
      name: e.target.value
    }))
  }

  function updateMealReceiptHandler(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
    dispatch(patchMeal({
      receipt: e.target.value
    }))
  }

  function deleteMealFoodstuffHandler(foodstuffId: string) {
    dispatch(deleteMealFoodstuff(foodstuffId))
  }

  const updateMealHandler = (type: string, value: string) => {
    if (type === 'NAME') {
      if (value == '') {
        dispatch(patchMeal({
          name: ' '
        }))
        return
      }
      dispatch(patchMeal({
        name: value
      }))
      return
    }
    if (type === 'RECEIPT') {
      if (value == '') {
        dispatch(patchMeal({
          receipt: ' '
        }))
        return
      }
      dispatch(patchMeal({
        receipt: value
      }))
      return
    }
  }

  const calculateBallancablaValue = (type: string, fs: Foodstuff): number => {
    const conversionFactor = (fs.measurementUnits?.find(mu => mu.selected)?.conversionFactor) || 0
    switch (type) {
      case 'ENERGY': {
        return (fs.energyInKcal || 0) * fs.weight!!
      }
      case 'PROTEIN': {
        return (fs.proteinMassFraction || 0) * fs.weight!!
      }
      case 'CARBOHYDRATE': {
        return (fs.carbohydrateMassFraction || 0) * fs.weight!!
      }
      case 'FAT': {
        return (fs.fatMassFraction || 0) * fs.weight!!
      }
      case 'FIBRE': {
        return (fs.fibreMassFraction || 0) * fs.weight!!
      }
      default: {
        return 0
      }
    }
  }

  const calculateTotalBallancableValue = (type: string): number => {
    return meal.meal.foodstuffs?.reduce((acc, i) => {
      acc += calculateBallancablaValue(type, i)
      return acc
    }, 0) || 0

  }

  function calculateTotalWeight(): number {
    return meal.meal.foodstuffs?.reduce((acc, i) => {
      acc+=i.weight!!
      return acc
    }, 0) || 0
  }

  return (
    <Card sx={{ minWidth: 275, margin: 5 }}>
      <CardContent>
        <Typography variant="h5">Pridať jedlo</Typography>
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column'
        }}>
          <TextField
            margin="normal"
            id="name"
            label="Názov"
            name="name"
            value={meal.meal.name}
            onChange={e => updateMealHandler('NAME', e.target.value)}
          />

          <TextField
            multiline
            margin="normal"
            required
            id="receipt"
            label="Recept"
            name="receipt"
            value={meal.meal.receipt}
            sx={{
              '& .MuiInputBase-root': { height: '150px' }
            }}
            onChange={e => updateMealHandler('RECEIPT', e.target.value)}
          />
        </Box>
        <InputLabel id="demo-simple-select-label">Typ jedla</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          onChange={(e: any) => {
            dispatch(patchMeal({
              mealtime: e.target.value
            }))
          }}
          defaultValue={'BREAKFAST'}
          label="kedy"
        >
          <MenuItem value={'BREAKFAST'}>Raňajky</MenuItem>
          <MenuItem value={'SNACK_AM'}>Desiata</MenuItem>
          <MenuItem value={'LUNCH'}>Obed</MenuItem>
          <MenuItem value={'SNACK_PM'}>Olovrant</MenuItem>
          <MenuItem value={'DINNER'}>Večera</MenuItem>
        </Select>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={meal.foodstuff.likeFoodstuffs}
          getOptionLabel={(option) => option.name || ""}
          onChange={(event: any, value: any) => {
            if (value == null) return
            dispatch(addFoodstuffToMeal(value))

          }}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Potravina"
            onChange={(e) => {
              if (e.target.value == '') {
                dispatch(setLikeFoodstuffs([]))
              }
              if (e.target.value.length > 2) {
                dispatch(getLikeFoodstuffs(e.target.value))
              }
            }}
            value={meal.foodstuff.likeFoodstuffs}
          />
          }
        />
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell align="right">Hmotnosť [g]</TableCell>
                <TableCell align="right">Energia [kcal]</TableCell>
                <TableCell align="right">Tuky [g]</TableCell>
                <TableCell align="right">Sacharidy [g]</TableCell>
                <TableCell align="right">Proteíny [g]</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {meal.meal.foodstuffs?.map((row, i) => (
                <TableRow
                  key={i}
                  sx={
                    { '&:last-child td, &:last-child th': { border: 0 } }
                  }

                >
                  <TableCell align="right">{row.name}</TableCell>
                  <TableCell onClick={() => deleteMealFoodstuffHandler(row.id!!)}><DeleteIcon /></TableCell>
                  <TableCell><Input value={row.value || 0} onChange={(e) => updateFoodstuffValueHandler(row.id!!, e.target.value)} /></TableCell>
                  <TableCell>{row.measurementUnits?.length!! > 1 ? <MeasurementUnits foodstuff={row} /> : <p>g</p>}</TableCell>
                  <TableCell align="right">
                    {((row.weight || 0)).toFixed(0)}
                  </TableCell>
                  <TableCell align="right">{calculateBallancablaValue('ENERGY', row).toFixed(0)}</TableCell>
                  <TableCell align="right">{calculateBallancablaValue('FAT', row).toFixed(0)}</TableCell>
                  <TableCell align="right">{calculateBallancablaValue('CARBOHYDRATE', row).toFixed(0)}</TableCell>
                  <TableCell align="right">{calculateBallancablaValue('PROTEIN', row).toFixed(0)}</TableCell>
                </TableRow>
              ))}
              {meal.meal.foodstuffs!!.length!! > 1 ? <TableRow>
                <TableCell>Celkem</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell align="right">{calculateTotalWeight().toFixed(0)}</TableCell>
                <TableCell align="right">{calculateTotalBallancableValue('ENERGY').toFixed(0)}</TableCell>
                <TableCell align="right">{calculateTotalBallancableValue('FAT').toFixed(0)}</TableCell>
                <TableCell align="right">{calculateTotalBallancableValue('CARBOHYDRATE').toFixed(0)}</TableCell>
                <TableCell align="right">{calculateTotalBallancableValue('PROTEIN').toFixed(0)}</TableCell>
              </TableRow> : null}
            </TableBody>
          </Table>
        </TableContainer>
        {((meal.meal.foodstuffs?.length || 0) > 1) && meal.meal.name && meal.meal.receipt &&

          <Button
            variant="contained"
            color="success"
            onClick={() => saveMealHandler()}
            sx={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            Uložiť {meal.meal.name}
          </Button>}
      </CardContent>
    </Card>
  )
}
