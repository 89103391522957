import { Box, TextField, Select, MenuItem, Typography, Button, OutlinedInput, InputLabel, Checkbox, ListItemText, SelectChangeEvent } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { updateNewUser } from "../../state/user/userSlice"
import { RootState } from "../../app/store"
import { useState } from "react";

export function NewClient(params: { coupon: string, submitHandler: () => void }) {
    const [selectedAllergen, setSelectedAllergen] = useState<string[]>([]);
    const { coupon, submitHandler } = params
    const dispatch = useDispatch<any>()
    let user = useSelector((state: RootState) => state.user.user)

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 200 * 4.5 + 20,
                width: 250,
            },
        },
    };

    const handleAllergenChange = (event: SelectChangeEvent<string[]>) => {
        const {
            target: { value },
        } = event;
        const allergens = typeof value === 'string' ? value.split(',') : value
        setSelectedAllergen(allergens);
        updateClientHandler('allergens', allergens)
    };


    const updateClientHandler = (name: string, value: any) => {
        dispatch(updateNewUser({
            name: name,
            value: value
        }))
    }

    const allergens = ['GLUTEN', 'SHELLFISH', 'EGGS', 'FISH', 'PEANUTS', 'SOY', 'MILK', 'NUTS', 'CELERY', 'MUSTARD', 'SESAME', 'SULFITE', 'LUPINUS', 'MOLLUSCS']

    return (<Box
        sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: '#e0e0e0',
            justifyContent: 'center',
            width: '100%'
        }}
    >
        <TextField
            margin="normal"
            required
            id="username"
            label="Uživateľské meno"
            name="username"
            onChange={(e) => { updateClientHandler('username', e.target.value) }}
        />
        <TextField
            margin="normal"
            required
            name="password"
            label="Heslo"
            id="password"
            onChange={(e) => { updateClientHandler('password', e.target.value) }}
        />

        <Select
            name="sex"
            label="Pohlavie"
            defaultValue={'MALE'}
            onChange={(e) => { updateClientHandler('sex', e.target.value) }}
        >
            <MenuItem value={'MALE'} defaultChecked={true}>Muž</MenuItem>
            <MenuItem value={'FEMALE'}>Žena</MenuItem>
        </Select>

        <TextField
            margin="normal"
            required
            name="firstName"
            label="Krstné meno"
            onChange={(e) => { updateClientHandler('firstName', e.target.value) }}
        />

        <TextField
            margin="normal"
            required
            name="lastName"
            label="Priezvisko"
            onChange={(e) => { updateClientHandler('lastName', e.target.value) }}
        />
        <InputLabel>Narodeniny</InputLabel>
        <TextField
            margin="normal"
            required
            type="date"
            name="birthdate"
            id="birthdate"
            onChange={(e) => { updateClientHandler('birthdate', e.target.value) }}
        />
        <InputLabel>Alergény</InputLabel>
        <Select
            multiple
            value={selectedAllergen}
            onChange={handleAllergenChange}
            input={<OutlinedInput label="Tag" />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
        >
            {allergens.map((name) => (
                <MenuItem key={name} value={name}>
                    <Checkbox checked={selectedAllergen.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                </MenuItem>
            ))}
        </Select>
        <InputLabel>Životný štýl</InputLabel>
        <Select
            name="physicalActivityLevel"
            label="physicalActivityLevel"
            defaultValue={'1.2'}
            onChange={(e) => { updateClientHandler('physicalActivityLevel', e.target.value) }}
        >
            <MenuItem value={'1.2'}>Extrémne neaktívny</MenuItem>
            <MenuItem value={'1.375'}>Sedavý</MenuItem>
            <MenuItem value={'1.55'}>Stredne aktívny</MenuItem>
            <MenuItem value={'1.725'}>Veľmi aktívny</MenuItem>
            <MenuItem value={'1.9'}>Profesionálny športovec</MenuItem>
        </Select>
        <InputLabel>Chuťové preferencie</InputLabel>
        <Select
            name="dietPreference"
            label="dietPreference"
            defaultValue={'NORMAL'}
            onChange={(e) => { updateClientHandler('dietPreference', e.target.value) }}
        >
            <MenuItem value={'NORMAL'}>Zjem všetko</MenuItem>
            <MenuItem value={'MEAT'}>Mám rád mäso</MenuItem>
            <MenuItem value={'CHEESE'}>Milujem syr</MenuItem>
            <MenuItem value={'VEGAN'}>Vegan</MenuItem>
            <MenuItem value={'VEGETARIAN'}>Vegetarián</MenuItem>
        </Select>
        <TextField
            margin="normal"
            required
            name="email"
            label="Email"
            type="email"
            id="email"
            onChange={(e) => { updateClientHandler('email', e.target.value) }}
        />
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center'
        }}>
            <Box p={5}>
                <Typography variant="h6" sx={{ width: '100%' }}>
                    Súčasné miery
                </Typography>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <TextField
                        margin="normal"
                        name="weight"
                        label="Váha"
                        type="number"
                        required
                        onChange={(e) => { updateClientHandler('currentWeight', e.target.value) }}
                    />
                    <TextField
                        margin="normal"
                        name="height"
                        label="Výška"
                        type="number"
                        required
                        onChange={(e) => { updateClientHandler('currentHeight', e.target.value) }}
                    />
                    <TextField
                        margin="normal"
                        name="chest"
                        label="Prsia"
                        type="number"
                        id="chest"
                        onChange={(e) => { updateClientHandler('currentChest', e.target.value) }}

                    />
                    <TextField
                        margin="normal"
                        name="waist"
                        label="Pás"
                        type="number"
                        id="waist"
                        onChange={(e) => { updateClientHandler('currentWaist', e.target.value) }}

                    />
                    <TextField
                        margin="normal"
                        name="hips"
                        label="Boky"
                        type="number"
                        id="hips"
                        onChange={(e) => { updateClientHandler('currentHips', e.target.value) }}

                    />
                    <TextField
                        margin="normal"
                        name="buttocks"
                        label="Zadok"
                        type="number"
                        id="buttocks"
                        onChange={(e) => { updateClientHandler('currentButtocks', e.target.value) }}

                    />
                    <TextField
                        margin="normal"
                        name="thigh"
                        label="Stehno"
                        type="number"
                        id="thigh"
                        onChange={(e) => { updateClientHandler('currentThigh', e.target.value) }}

                    />
                    <TextField
                        margin="normal"
                        name="calf"
                        label="Lýtko"
                        type="number"
                        id="calf"
                        onChange={(e) => { updateClientHandler('currentCalf', e.target.value) }}
                    />
                </Box>
            </Box>
            <Box p={5}>
                <Typography variant="h6" sx={{ width: '100%' }}>
                    Cieľové miery
                </Typography>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <TextField
                        margin="normal"
                        name="weight"
                        label="Váha"
                        type="number"
                        required
                        onChange={(e) => { updateClientHandler('plannedWeight', e.target.value) }}
                    />
                    <TextField
                        margin="normal"
                        disabled
                    />
                    <TextField
                        margin="normal"
                        name="chest"
                        label="Prsia"
                        type="number"
                        id="chest"
                        onChange={(e) => { updateClientHandler('plannedChest', e.target.value) }}

                    />
                    <TextField
                        margin="normal"
                        name="waist"
                        label="Pás"
                        type="number"
                        id="waist"
                        onChange={(e) => { updateClientHandler('plannedWaist', e.target.value) }}

                    />
                    <TextField
                        margin="normal"
                        name="hips"
                        label="Boky"
                        type="number"
                        id="hips"
                        onChange={(e) => { updateClientHandler('plannedHips', e.target.value) }}

                    />
                    <TextField
                        margin="normal"
                        name="buttocks"
                        label="Zadok"
                        type="number"
                        id="buttocks"
                        onChange={(e) => { updateClientHandler('plannedButtocks', e.target.value) }}

                    />
                    <TextField
                        margin="normal"
                        name="thigh"
                        label="Stehno"
                        type="number"
                        id="thigh"
                        onChange={(e) => { updateClientHandler('plannedThigh', e.target.value) }}

                    />
                    <TextField
                        margin="normal"
                        name="calf"
                        label="Lýtko"
                        type="number"
                        id="calf"
                        onChange={(e) => { updateClientHandler('plannedCalf', e.target.value) }}
                    />
                </Box>
            </Box>
        </Box>
        <Button color={"success"} variant={"contained"} onClick={submitHandler}>Uložiť</Button>
    </Box>)
}