import { Box, Typography } from "@mui/material";

export function DietTips() {

    return (
<Box p={5} style={{ background: '#EFE4E1', borderRadius: 10 }} sx={{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
}}>
    <Typography variant="h3" style={{ marginBottom: 10, color: '#582f0e', fontWeight: 'bold' }}>AKO NA TO?</Typography>
    <Typography variant="h4" style={{ marginBottom: 5 }}>VODA</Typography>
    <Typography style={{ marginBottom: 15 }}>Denne prijať 3.5l tekutín (čistá voda, zelený čaj, voda s citrónom)</Typography>
    <Typography style={{ marginBottom: 15 }}>Tréningový deň 4l tekutín (čistá voda, zelený čaj, voda s citrónom)</Typography>

    <Typography variant="h4" style={{ marginBottom: 5 }}>ZELENINA</Typography>
    <Typography style={{ marginBottom: 15 }}>Zeleninu môžte rôzné druhy a rôzne tepelne upravované (na pare, grilovanú, dusenú, verenú) alebo čerstvú</Typography>

    <Typography variant="h4" style={{ marginBottom: 5 }}>OVOCIE</Typography>
    <Typography style={{ marginBottom: 10 }}>1/ višňa, mango, maliny, liči, jablko, hruška, čerešně, hrozno</Typography>
    <Typography style={{ marginBottom: 15 }}>2/ broskyňa, čučoriedky, jahody, mandarínka, marhuľa, nektárinka, papája</Typography>

    <Typography variant="h4" style={{ marginBottom: 5 }}>KÁVA</Typography>
    <Typography style={{ marginBottom: 15 }}>Zeleninu môžte rôzné druhy a rôzne tepelne upravované (na pare, grilovanú, dusenú, varenú) alebo čerstvú</Typography>
</Box>
    )
}
