import { Box, Button, Card, CardContent, CardHeader, Link, MenuItem, Select, TextField, Typography } from "@mui/material";
import { getConsumedMeals, patchFoodstuff, patchMeal, saveConsumedMeal, selectMeals, updateConsumedMealFoodstuffWeight, updateConsumedMealMealtime, updateConsumedMealName } from "../../state/meals/mealSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { ConsumedMeal as ConsumedMealDTO, Foodstuff, Meal } from "../..";
import { useEffect } from "react";
import { Gauge } from "../common/Gauge";
import { ConsumedMealDetail } from "./ConsumedMealDetail";
import './consumedMeal.css'


export function ConsumedMeal() {

  useEffect(() => {
    dispatch(getConsumedMeals())
  }, [])

  const consumedMeal = useSelector((state: RootState) => state.meal.consumedMeal.meal)
  const consumedMeals = useSelector((state: RootState) => state.meal.consumedMeals)
  const user = useSelector((state: RootState) => state.user.user)
  const dispatch = useDispatch<any>()


  function consumedMealHandler() {
    dispatch(saveConsumedMeal(consumedMeal))
  }

  const consumedValues = () => {
    return consumedMeals.flatMap(meal => {
      return meal.foodstuffs!!
    }).reduce((acc, item) => {
      acc["ENERGY"] += item.energyInKcal!! * item.weight!!
      acc["PROTEIN"] += item.proteinMassFraction!! * item.weight!!
      acc["FAT"] += item.fatMassFraction!! * item.weight!!
      acc["CARBOHYDRATE"] += item.carbohydrateMassFraction!! * item.weight!!
      return acc
    }, {
      "ENERGY": 0.0,
      "PROTEIN": 0.0,
      "FAT": 0.0,
      "CARBOHYDRATE": 0.0
    })
  }

  const consumedEnergy = consumedValues()["ENERGY"].toFixed(1);

  const groupedConsumedMeals = consumedMeals.reduce<Record<string, ConsumedMealDTO[]>>((acc, meal) => {
    switch (meal.mealtime) {
      case 'BREAKFAST':
        acc['BREAKFAST'].push(meal)
        break;
      case 'SNACK_AM':
        acc['SNACK_AM'].push(meal)
        break;
      case 'LUNCH':
        acc['LUNCH'].push(meal)
        break;
      case 'SNACK_PM':
        acc['SNACK_PM'].push(meal)
        break;
      case 'DINNER':
        acc['DINNER'].push(meal)
        break;
    }
    return acc;
  }, {
    "BREAKFAST": [],
    "SNACK_AM": [],
    "LUNCH": [],
    "SNACK_PM": [],
    "DINNER": []
  });


  const targetValues = () => {
    return {
      "ENERGY": (user.mealtimeIntake!!['BREAKFAST']['ENERGY']
        + user.mealtimeIntake!!['SNACK_AM']['ENERGY']
        + user.mealtimeIntake!!['LUNCH']['ENERGY']
        + user.mealtimeIntake!!['SNACK_PM']['ENERGY']
        + user.mealtimeIntake!!['DINNER']['ENERGY']).toFixed(1),
      "PROTEIN": (user.mealtimeIntake!!['BREAKFAST']['PROTEIN']
        + user.mealtimeIntake!!['SNACK_AM']['PROTEIN']
        + user.mealtimeIntake!!['LUNCH']['PROTEIN']
        + user.mealtimeIntake!!['SNACK_PM']['PROTEIN']
        + user.mealtimeIntake!!['DINNER']['PROTEIN']).toFixed(1),
      "FAT": (user.mealtimeIntake!!['BREAKFAST']['FAT']
        + user.mealtimeIntake!!['SNACK_AM']['FAT']
        + user.mealtimeIntake!!['LUNCH']['FAT']
        + user.mealtimeIntake!!['SNACK_PM']['FAT']
        + user.mealtimeIntake!!['DINNER']['FAT']).toFixed(1),
      "CARBOHYDRATE": (user.mealtimeIntake!!['BREAKFAST']['CARBOHYDRATE']
        + user.mealtimeIntake!!['SNACK_AM']['CARBOHYDRATE']
        + user.mealtimeIntake!!['LUNCH']['CARBOHYDRATE']
        + user.mealtimeIntake!!['SNACK_PM']['CARBOHYDRATE']
        + user.mealtimeIntake!!['DINNER']['CARBOHYDRATE']).toFixed(1)
    }
  }

  const consumedTargetPercentage = (key: string) => {
    switch (key) {
      case 'ENERGY': return (100 * consumedValues()['ENERGY'] / parseFloat(targetValues()['ENERGY'])).toFixed(0)
      case 'PROTEIN': return (100 * consumedValues()['PROTEIN'] / parseFloat(targetValues()['PROTEIN'])).toFixed(0)
      case 'FAT': return (100 * consumedValues()['FAT'] / parseFloat(targetValues()['FAT'])).toFixed(0)
      case 'CARBOHYDRATE': return (100 * consumedValues()['CARBOHYDRATE'] / parseFloat(targetValues()['CARBOHYDRATE'])).toFixed(0)
    }
  }



  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    }}>
      <Typography variant="h2">Denný prehled</Typography>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }} m={10}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
          <Typography variant="h4">ENERGIA: {consumedEnergy} kcal / {targetValues()['ENERGY']} kcal</Typography>
          <Typography variant="h4">{consumedTargetPercentage('ENERGY')} %</Typography>
          <Gauge value={consumedTargetPercentage('ENERGY')} />
        </Box>
        <Box sx={{
          display: 'flex'
        }} className="media-flex-direction">
          <Box sx={{
            flexDirection: 'column'
          }}>
            <Typography variant="h5">PROTEIN: {consumedValues()['PROTEIN']} g / {targetValues()['PROTEIN']} g</Typography>
            <Typography variant="h5">{consumedTargetPercentage('PROTEIN')} %</Typography>
            <Gauge value={consumedTargetPercentage('PROTEIN')} />
          </Box>

          <Box sx={{
            flexDirection: 'column'
          }}>
            <Typography variant="h5">SACHARIDY: {consumedValues()['CARBOHYDRATE']} g / {targetValues()['CARBOHYDRATE']} g</Typography>
            <Typography variant="h5">{consumedTargetPercentage('CARBOHYDRATE')} %</Typography>
            <Gauge value={consumedTargetPercentage('CARBOHYDRATE')} />
          </Box>
          <Box sx={{
            flexDirection: 'column'
          }}>
            <Typography variant="h5">TUK: {consumedValues()['FAT']} g / {targetValues()['FAT']} g</Typography>
            <Typography variant="h5">{consumedTargetPercentage('FAT')} %</Typography>
            <Gauge value={consumedTargetPercentage('FAT')} />
          </Box>
        </Box>
      </Box>
      <Box>

        <Typography variant="h3">Dnešná jedlá</Typography>

        <Box sx={{
          display: 'flex'
        }} className="media-flex-direction">
          {groupedConsumedMeals['BREAKFAST'].map(m => {
            return (
              <ConsumedMealDetail meal={m}></ConsumedMealDetail>
            )
          })}
          {groupedConsumedMeals['SNACK_AM'].map(m => {
            return (
              <ConsumedMealDetail meal={m}></ConsumedMealDetail>
            )
          })}
          {groupedConsumedMeals['LUNCH'].map(m => {
            return (
              <ConsumedMealDetail meal={m}></ConsumedMealDetail>
            )
          })}
          {groupedConsumedMeals['SNACK_PM'].map(m => {
            return (
              <ConsumedMealDetail meal={m}></ConsumedMealDetail>
            )
          })}
          {groupedConsumedMeals['DINNER'].map(m => {
            return (
              <ConsumedMealDetail meal={m}></ConsumedMealDetail>
            )
          })}
        </Box>
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
      }}>
        <Typography variant="h3">Zaznamenat zjedene jedlo</Typography>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={consumedMeal.mealtime}
          label="kedy"
          onChange={e => dispatch(updateConsumedMealMealtime({ mealtime: e.target.value }))}
        >
          <MenuItem value={'BREAKFAST'}>Raňajky</MenuItem>
          <MenuItem value={'SNACK_AM'}>Desiata</MenuItem>
          <MenuItem value={'LUNCH'}>Obed</MenuItem>
          <MenuItem value={'SNACK_PM'}>Olovrant</MenuItem>
          <MenuItem value={'DINNER'}>Večera</MenuItem>
        </Select>
        <TextField
          margin="normal"
          required
          id="name"
          label="Názov"
          name="name"
          value={consumedMeal.name}
          onChange={e => dispatch(updateConsumedMealName({ name: e.target.value }))}
        />
        {consumedMeal.foodstuffs?.map(fs => {
          return (
            <Box>{fs.name} <TextField
              margin="normal"
              required
              label="Hmotnost"
              name="name"
              value={fs.weight}
              onChange={e => dispatch(updateConsumedMealFoodstuffWeight({ foodstuffId: fs.id, weight: e.target.value }))}
            /></Box>
          )
        })
        }
        <Button variant="contained" color="info" onClick={() => { consumedMealHandler() }}>Uložit</Button>
      </Box>
    </Box >
  )
}

