import { useSelector, useDispatch } from "react-redux"
import { fetchMealsDraft, updateFoodstuffLock, updateFoodstuffWeight, calculateFoodstuffs, updateTargetValue, updateCalculatedMealId, saveMealForTrainee, deleteDraftMeal } from "../../state/meals/mealSlice"
import { Box, Button, TextField } from "@mui/material"

import { RootState } from "../../app/store"
import { calculateValues } from "./DietService"
import { Meal, Foodstuff } from "../.."
import ColorService from "../../common/ColorService"
import { MealtimeAssignement } from "./MealtimeAssignment"

export function DietCalculation() {

    const dispatch = useDispatch<any>()

    let mealsDrafts = useSelector((state: RootState) => state.meal.mealsDrafts)
    let clientMeals = useSelector((state: RootState) => state.client.currentClient?.meals)

    const calculateFoodstuffsHandler = (meal: Meal) => {
        dispatch(updateCalculatedMealId(`${meal.mealtime}|${meal.id}`))
        dispatch(calculateFoodstuffs({
            foodstuffs: meal.foodstuffs!!,
            targetValues: mealsDrafts.targetValues[meal.mealtime!!]
        }))
    }

    const deleteFoodstuffsHandler = (meal: Meal) => {
        dispatch(deleteDraftMeal({ meal }))
    }

    const saveFoodstuffsHandler = (meal: Meal) => {
        dispatch(saveMealForTrainee(meal))
    }


    function updateFoodstuffWeightHandler(meal: Meal, foodstuff: Foodstuff, value: string) {
        const numericValue = parseFloat(value)
        if (isNaN(numericValue)) {
            dispatch(updateFoodstuffWeight({ meal, foodstuff, value: 0 }))
        }
        else {
            dispatch(updateFoodstuffWeight({ meal, foodstuff, value: numericValue }))
        }

    }

    function toggleFoodstuffLockHandler(meal: Meal, foodstuff: Foodstuff) {
        dispatch(updateFoodstuffLock({ meal, foodstuff }))
    }

    const calculatePercentageDifference = (meal: Meal, valueType: string) => {
        return Number(100 * calculateValues(meal)[valueType] / mealsDrafts.targetValues[meal.mealtime!!][valueType]!! - 100).toFixed(1)
    }

    const calculateBackgroundColor = (meal: Meal, valueType: string): string => {
        let value = calculateValues(meal)[valueType] / mealsDrafts.targetValues[meal.mealtime!!][valueType]!!
        if (value < 0.85) {
            return '#100ea5'
        } else if (value < 0.95) {
            return '#3ca7d9'
        } else if (value > 1.15) {
            return '#e7e40e'
        } else if (value > 1.05) {
            return "#de362e"
        }
        else {
            return "#ffffff"
        }

    }

    const updateTargetWeightHandler = (mealtime: string, targetKey: string, value: any) => {
        if (isNaN(value)) {
            return
        }
        dispatch(updateTargetValue({
            mealtime: mealtime,
            targetKey: targetKey,
            value: value
        }))
    }

    function calculateMealsHandler() {
        dispatch(fetchMealsDraft(mealsDrafts.targetValues))
    }

    return (
        <Box>
            <Box m={10}>
                <Box sx={{
                    backgroundColor: ColorService.getColor('BREAKFAST')
                }}>
                    <h3>raňajky</h3>
                    <TextField label="Energie [kcal]" value={mealsDrafts.targetValues['BREAKFAST']['ENERGY']} onChange={(e) => updateTargetWeightHandler('BREAKFAST', 'ENERGY', e.target.value)} />
                    <TextField label="Protein [g]" value={mealsDrafts.targetValues['BREAKFAST']['PROTEIN']} onChange={(e) => updateTargetWeightHandler('BREAKFAST', 'PROTEIN', e.target.value)} />
                    <TextField label="Tuk [g]" value={mealsDrafts.targetValues['BREAKFAST']['FAT']} onChange={(e) => updateTargetWeightHandler('BREAKFAST', 'FAT', e.target.value)} />
                    <TextField label="Sacharidy [g]" value={mealsDrafts.targetValues['BREAKFAST']['CARBOHYDRATE']} onChange={(e) => updateTargetWeightHandler('BREAKFAST', 'CARBOHYDRATE', e.target.value)} />
                    <TextField label="Vlaknina [g]" value={mealsDrafts.targetValues['BREAKFAST']['FIBRE']} onChange={(e) => updateTargetWeightHandler('BREAKFAST', 'FIBRE', e.target.value)} />
                </Box>
                <MealtimeAssignement meals={mealsDrafts.meals['BREAKFAST']}></MealtimeAssignement>
                <Box sx={{
                    backgroundColor: ColorService.getColor('SNACK_AM')
                }}>
                    <h3>desiata</h3>
                    <TextField label="Energie [kcal]" value={mealsDrafts.targetValues['SNACK_AM']['ENERGY']} onChange={(e) => updateTargetWeightHandler('SNACK_AM', 'ENERGY', e.target.value)} />
                    <TextField label="Protein [g]" value={mealsDrafts.targetValues['SNACK_AM']['PROTEIN']} onChange={(e) => updateTargetWeightHandler('SNACK_AM', 'PROTEIN', e.target.value)} />
                    <TextField label="Tuk [g]" value={mealsDrafts.targetValues['SNACK_AM']['FAT']} onChange={(e) => updateTargetWeightHandler('SNACK_AM', 'FAT', e.target.value)} />
                    <TextField label="Sacharidy [g]" value={mealsDrafts.targetValues['SNACK_AM']['CARBOHYDRATE']} onChange={(e) => updateTargetWeightHandler('SNACK_AM', 'CARBOHYDRATE', e.target.value)} />
                    <TextField label="Vlaknina [g]" value={mealsDrafts.targetValues['SNACK_AM']['FIBRE']} onChange={(e) => updateTargetWeightHandler('SNACK_AM', 'FIBRE', e.target.value)} />
                </Box>
                <MealtimeAssignement meals={mealsDrafts.meals['SNACK_AM']}></MealtimeAssignement>
                <Box sx={{
                    backgroundColor: ColorService.getColor('LUNCH')
                }}>
                    <h3>obed</h3>
                    <TextField label="Energie [kcal]" value={mealsDrafts.targetValues['LUNCH']['ENERGY']} onChange={(e) => updateTargetWeightHandler('LUNCH', 'ENERGY', e.target.value)} />
                    <TextField label="Protein [g]" value={mealsDrafts.targetValues['LUNCH']['PROTEIN']} onChange={(e) => updateTargetWeightHandler('LUNCH', 'PROTEIN', e.target.value)} />
                    <TextField label="Tuk [g]" value={mealsDrafts.targetValues['LUNCH']['FAT']} onChange={(e) => updateTargetWeightHandler('LUNCH', 'FAT', e.target.value)} />
                    <TextField label="Sacharidy [g]" value={mealsDrafts.targetValues['LUNCH']['CARBOHYDRATE']} onChange={(e) => updateTargetWeightHandler('LUNCH', 'CARBOHYDRATE', e.target.value)} />
                    <TextField label="Vlaknina [g]" value={mealsDrafts.targetValues['LUNCH']['FIBRE']} onChange={(e) => updateTargetWeightHandler('LUNCH', 'FIBRE', e.target.value)} />
                </Box>
                <MealtimeAssignement meals={mealsDrafts.meals['LUNCH']}></MealtimeAssignement>
                <Box sx={{
                    backgroundColor: ColorService.getColor('SNACK_PM')
                }}>
                    <h3>olovrant</h3>
                    <TextField label="Energie [kcal]" value={mealsDrafts.targetValues['SNACK_PM']['ENERGY']} onChange={(e) => updateTargetWeightHandler('SNACK_PM', 'ENERGY', e.target.value)} />
                    <TextField label="Protein [g]" value={mealsDrafts.targetValues['SNACK_PM']['PROTEIN']} onChange={(e) => updateTargetWeightHandler('SNACK_PM', 'PROTEIN', e.target.value)} />
                    <TextField label="Tuk [g]" value={mealsDrafts.targetValues['SNACK_PM']['FAT']} onChange={(e) => updateTargetWeightHandler('SNACK_PM', 'FAT', e.target.value)} />
                    <TextField label="Sacharidy [g]" value={mealsDrafts.targetValues['SNACK_PM']['CARBOHYDRATE']} onChange={(e) => updateTargetWeightHandler('SNACK_PM', 'CARBOHYDRATE', e.target.value)} />
                    <TextField label="Vlaknina [g]" value={mealsDrafts.targetValues['SNACK_PM']['FIBRE']} onChange={(e) => updateTargetWeightHandler('SNACK_PM', 'FIBRE', e.target.value)} />
                </Box>
                <MealtimeAssignement meals={mealsDrafts.meals['SNACK_PM']}></MealtimeAssignement>
                <Box sx={{
                    backgroundColor: ColorService.getColor('DINNER')
                }}>
                    <h3>večera</h3>
                    <TextField label="Energie [kcal]" value={mealsDrafts.targetValues['DINNER']['ENERGY']} onChange={(e) => updateTargetWeightHandler('DINNER', 'ENERGY', e.target.value)} />
                    <TextField label="Protein [g]" value={mealsDrafts.targetValues['DINNER']['PROTEIN']} onChange={(e) => updateTargetWeightHandler('DINNER', 'PROTEIN', e.target.value)} />
                    <TextField label="Tuk [g]" value={mealsDrafts.targetValues['DINNER']['FAT']} onChange={(e) => updateTargetWeightHandler('DINNER', 'FAT', e.target.value)} />
                    <TextField label="Sacharidy [g]" value={mealsDrafts.targetValues['DINNER']['CARBOHYDRATE']} onChange={(e) => updateTargetWeightHandler('DINNER', 'CARBOHYDRATE', e.target.value)} />
                    <TextField label="Vlaknina [g]" value={mealsDrafts.targetValues['DINNER']['FIBRE']} onChange={(e) => updateTargetWeightHandler('DINNER', 'FIBRE', e.target.value)} />
                </Box>
                <MealtimeAssignement meals={mealsDrafts.meals['DINNER']}></MealtimeAssignement>
                <Button
                    onClick={() => calculateMealsHandler()}
                    type="submit"
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Spocitat
                </Button>
            </Box>
        </Box>
    )
}