/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { User } from '../models/User';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserControllerService {

    /**
     * @returns User OK
     * @throws ApiError
     */
    public static getUser(): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user',
        });
    }

    /**
     * @param requestBody
     * @returns User OK
     * @throws ApiError
     */
    public static createUser(
        requestBody: User,
    ): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param coupon
     * @returns any OK
     * @throws ApiError
     */
    public static checkCoupon(
        coupon: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/onboard/coupon/{coupon}',
            path: {
                'coupon': coupon,
            },
        });
    }

    /**
     * @param coupon
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static onboardWithCoupon(
        coupon: string,
        requestBody: User,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user/onboard/coupon/{coupon}',
            path: {
                'coupon': coupon,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns string OK
     * @throws ApiError
     */
    public static login(
        requestBody: User,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user/login',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns User OK
     * @throws ApiError
     */
    public static getTrainees(): CancelablePromise<Array<User>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/trainees',
        });
    }

    /**
     * @param id
     * @returns User OK
     * @throws ApiError
     */
    public static getUserById(
        id: string,
    ): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/id/{id}',
            path: {
                'id': id,
            },
        });
    }

}
