import { Box, Button, Card, CardContent, Checkbox, FormControlLabel, InputLabel, ListItemText, MenuItem, OutlinedInput, Radio, RadioGroup, Select, SelectChangeEvent, TextField, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { patchFoodstuff, saveFoodstuff } from "../../state/meals/mealSlice";
import { RootState } from "../../app/store";
import { Foodstuff } from "../../models/Foodstuff";
import { MeasurementUnit } from "../../models/MeasurementUnit";
import { useEffect, useState } from "react";

export function AddFoodstuff() {

  const [selectedMeasurementUnits, setSelectedMeasurementUnits] = useState<string[]>([])
  const [baseMeasurementUnit, setBaseMeasurementUnit] = useState<'MASS' | 'VOLUME'>('MASS')

  const dispatch = useDispatch<any>()

  useEffect(() => {
    dispatch(patchFoodstuff({ measurementUnits: selectedMeasurementUnits }))
  }, [selectedMeasurementUnits, dispatch])


  const foodstuff = useSelector((state: RootState) => state.meal.foodstuff.foodstuff)

  const measurementUnits: MeasurementUnit[] = [  { name: 'milliliter', symbol: 'l', conversionFactor: 0, order: 0, selected: false },
  { name: 'liter', symbol: 'l', conversionFactor: 0, order: 0, selected: false },
  { name: 'teaspoon', symbol: 'l', conversionFactor: 0, order: 0, selected: false },
  { name: 'kilogram', symbol: 'l', conversionFactor: 0, order: 0, selected: false },
  { name: 'tablespoon', symbol: 'l', conversionFactor: 0, order: 0, selected: false }]


  const handleSubmit = () => {

    const usedUnits = measurementUnits.filter(mu => selectedMeasurementUnits.includes(mu.name))

    dispatch(saveFoodstuff({
      name: foodstuff.name,
      density: (foodstuff.density ?? 0),
      languageCode: Foodstuff.languageCode.SK,
      energyInKcal: (foodstuff.energyInKcal ?? 0) / 100,
      proteinMassFraction: (foodstuff.proteinMassFraction ?? 0) / 100,
      carbohydrateMassFraction: (foodstuff.carbohydrateMassFraction ?? 0) / 100,
      fatMassFraction: (foodstuff.fatMassFraction ?? 0) / 100,
      fibreMassFraction: (foodstuff.fibreMassFraction ?? 0) / 100,
      measurementUnits: usedUnits
    }))
  }

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 200 * 4.5 + 20,
        width: 250,
      },
    },
  };

  const baseUnitSymbol = baseMeasurementUnit == "MASS" ? 'g' : 'ml'

  const prettifyString = (input: string): string => {
    if (/^\d+\.?\d*$/.test(input)) {
      if (input.includes('.')) {
        if (input.at(input.length - 1) == '.') {
          return input
        }
        return parseFloat(input).toString();
      } else {
        return parseInt(input, 10).toString();
      }
    }
    return input;
  }

  const isValidNumber = (input: string) => {
    const regex = /^\d*\.?\d*$/
    return regex.test(input);
  }

  const updateFoodstuffHandler = (type: string, value: string) => {
    if (type === 'NAME') {
      if (value == '') {
        dispatch(patchFoodstuff({
          name: ' '
        }))
        return
      }
      dispatch(patchFoodstuff({
        name: value
      }))
      return
    }
    if (!isValidNumber(value)) {
      return
    }
    let finalValue = prettifyString(value) == '' ? '0' : prettifyString(value)
    switch (type) {
      case 'ENERGY': {
        dispatch(patchFoodstuff({ energyInKcal: finalValue }))
        return
      }
      case 'DENSITY': {
        dispatch(patchFoodstuff({ density: finalValue }))
        return
      }
      case 'CARBOHYDRATE': {
        dispatch(patchFoodstuff({ carbohydrateMassFraction: finalValue }))
        return
      }
      case 'PROTEIN': {
        dispatch(patchFoodstuff({ proteinMassFraction: finalValue }))
        return
      }
      case 'FAT': {
        dispatch(patchFoodstuff({ fatMassFraction: finalValue }))
        return
      }
      case 'FIBRE': {
        dispatch(patchFoodstuff({ fibreMassFraction: finalValue }))
        return
      }
    }
  }

  const handleMeasurementUnitChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    const values = typeof value === 'string' ? value.split(',') : value
    setSelectedMeasurementUnits(values)
  };

  function baseMeasurementUnitHandler(value: 'MASS' | 'VOLUME'): void {
    setBaseMeasurementUnit(value)
  }

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography variant="h5" component="div">
          Pridať potravinu
        </Typography>

        <Box component="form" sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}>
          <TextField
            margin="normal"
            id="name"
            label="Názov"
            name="name"
            value={foodstuff.name || ''}
            onChange={e => updateFoodstuffHandler('NAME', e.target.value)}
          />
          <RadioGroup
            row
            value={baseMeasurementUnit}
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <FormControlLabel value="MASS" onClick={e => baseMeasurementUnitHandler("MASS")} control={<Radio />} label="kcal / 100g" />
            <FormControlLabel value="VOLUME" onClick={e => baseMeasurementUnitHandler("VOLUME")} control={<Radio />} label="kcal / 100ml" />
          </RadioGroup>

          {baseMeasurementUnit == 'VOLUME' ? <TextField
            margin="normal"
            id="density"
            label="Hustota [g / ml]"
            name="density"
            value={foodstuff.density || 1}
            onChange={e => updateFoodstuffHandler('DENSITY', e.target.value)}
          /> : null}

          <TextField
            margin="normal"
            id="energy"
            label={`Energia [kcal / 100 ${baseUnitSymbol}]`}
            name="energy"
            value={foodstuff.energyInKcal || 0}
            onChange={e => updateFoodstuffHandler('ENERGY', e.target.value)}
          />

          <TextField
            margin="normal"
            id="protein"
            label={`Protein [g / 100 ${baseUnitSymbol}]`}
            name="proteín"
            value={foodstuff.proteinMassFraction || 0}
            onChange={e => updateFoodstuffHandler('PROTEIN', e.target.value)}
          />

          <TextField
            margin="normal"
            id="carbohydrate"
            label={`Sacharidy [g / 100 ${baseUnitSymbol}]`}
            name="carbohydrate"
            value={foodstuff.carbohydrateMassFraction || 0}
            onChange={e => updateFoodstuffHandler('CARBOHYDRATE', e.target.value)}
          />

          <TextField
            margin="normal"
            id="fat"
            label={`Tuky [g / 100 ${baseUnitSymbol}]`}
            name="fat"
            value={foodstuff.fatMassFraction || 0}
            onChange={e => updateFoodstuffHandler('FAT', e.target.value)}
          />
          <TextField
            margin="normal"
            id="fibre"
            label={`Vláknina [g / 100 ${baseUnitSymbol}]`}
            name="fibre"
            value={foodstuff.fibreMassFraction || 0}
            onChange={e => updateFoodstuffHandler('FIBRE', e.target.value)}
          />
          <InputLabel>Odmerné jednotky</InputLabel>
          <Select
            multiple
            value={selectedMeasurementUnits}
            onChange={handleMeasurementUnitChange}
            input={<OutlinedInput label="Tag" />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
          >
            {measurementUnits.map((mu) => (
              <MenuItem key={mu.name} value={mu.name}>
                <Checkbox checked={selectedMeasurementUnits.indexOf(mu.name) > -1} />
                <ListItemText primary={mu.name} />
              </MenuItem>
            ))}
          </Select>
          <Button
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={() => handleSubmit()}
          >
            Pridať {foodstuff.name}
          </Button>
        </Box>
      </CardContent>
    </Card>
  )
}
