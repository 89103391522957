import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { JwtPayload, jwtDecode } from "jwt-decode";
import { User, OpenAPI, UserControllerService, BodyMeasurements } from "../.."
import { RootState } from "../../app/store";

const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

interface UserState {
    loginStatus: 'NEW' | 'LOADING' | 'OK' | 'ERROR'
    user: User,
    newUser: User,
    jwt?: string,
    timezone: string
}

interface KonceptJwtPayload extends JwtPayload {
    userId: string;
    authorities: string[];
}

const initialState: UserState = {
    loginStatus: 'NEW',
    user: {
        sex: User.sex.MALE,
        physicalActivityLevel: 1.2,
        bodyMeasurements: [{
            type: BodyMeasurements.type.ACTUAL
        },
        {
            type: BodyMeasurements.type.PLAN
        }],
        timezone: timeZone
    },
    newUser: {
        sex: User.sex.MALE,
        physicalActivityLevel: 1.2,
        bodyMeasurements: [{
            type: BodyMeasurements.type.ACTUAL
        },
        {
            type: BodyMeasurements.type.PLAN
        }],
        timezone: timeZone
    },
    timezone: timeZone
}

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        logout: (state) => {
            state.user = {}
            state.jwt = ''
        },
        updateNewUser: (state, action) => {
            switch (action.payload.name) {
                case 'username': state.newUser.username = action.payload.value
                    break
                case 'password': state.newUser.password = action.payload.value
                    break
                case 'sex': state.newUser.sex = action.payload.value
                    break
                case 'firstName': state.newUser.firstName = action.payload.value
                    break
                case 'lastName': state.newUser.lastName = action.payload.value
                    break
                case 'birthdate': state.newUser.birthdate = action.payload.value
                    break
                case 'physicalActivityLevel': state.newUser.physicalActivityLevel = action.payload.value
                    break
                case 'dietPreference': state.newUser.dietPreference = action.payload.value
                    break
                case 'email': state.newUser.email = action.payload.value
                    break
                case 'currentWeight': state.newUser.bodyMeasurements!![0].weight = action.payload.value
                    break
                case 'currentHeight': state.newUser.bodyMeasurements!![0].height = action.payload.value
                    break
                case 'currentChest': state.newUser.bodyMeasurements!![0].chest = action.payload.value
                    break
                case 'currentWaist': state.newUser.bodyMeasurements!![0].waist = action.payload.value
                    break
                case 'currentHips': state.newUser.bodyMeasurements!![0].hips = action.payload.value
                    break
                case 'currentButtocks': state.newUser.bodyMeasurements!![0].buttocks = action.payload.value
                    break
                case 'currentThigh': state.newUser.bodyMeasurements!![0].thigh = action.payload.value
                    break
                case 'currentCalf': state.newUser.bodyMeasurements!![0].calf = action.payload.value
                    break
                case 'plannedWeight': state.newUser.bodyMeasurements!![1].weight = action.payload.value
                    break
                case 'plannedChest': state.newUser.bodyMeasurements!![1].chest = action.payload.value
                    break
                case 'plannedWaist': state.newUser.bodyMeasurements!![1].waist = action.payload.value
                    break
                case 'plannedHips': state.newUser.bodyMeasurements!![1].hips = action.payload.value
                    break
                case 'plannedButtocks': state.newUser.bodyMeasurements!![1].buttocks = action.payload.value
                    break
                case 'plannedThigh': state.newUser.bodyMeasurements!![1].thigh = action.payload.value
                    break
                case 'plannedCalf': state.newUser.bodyMeasurements!![1].calf = action.payload.value
                    break
                case 'allergens': state.newUser.allergies = action.payload.value
                    break
            }
        },
        updateTimezone: (state, action) => {
            state.timezone = action.payload
            state.user.timezone = action.payload
            state.newUser.timezone = action.payload
        }
    },
    extraReducers(builder) {
        builder.addCase(login.pending, (state, action) => {
            state.loginStatus = 'LOADING'
        })
            .addCase(login.fulfilled, (state, action) => {
                state.loginStatus = 'OK'
                state.jwt = action.payload
                OpenAPI.TOKEN = `${action.payload}`
                const token: KonceptJwtPayload = jwtDecode(action.payload)

                const user: User = {
                    id: token['userId'],
                    roles: token['authorities']
                }
                state.user = user

            })
            .addCase(login.rejected, (state) => {
                state.loginStatus = 'ERROR'
            })
            .addCase(getUser.fulfilled, (state, action) => {
                state.user = action.payload
            })
            .addCase(onboardUser.fulfilled, (state) => {
                state = {
                    loginStatus: 'NEW',
                    user: {
                        bodyMeasurements: [{
                            type: BodyMeasurements.type.ACTUAL
                        },
                        {
                            type: BodyMeasurements.type.PLAN
                        }]
                    },
                    newUser: {
                        bodyMeasurements: [{
                            type: BodyMeasurements.type.ACTUAL
                        },
                        {
                            type: BodyMeasurements.type.PLAN
                        }]
                    },
                    timezone: timeZone
                }
            })
    },
})

export const login = createAsyncThunk('user/login', async (user: User) => {
    const response = await UserControllerService.login(user)
    return response
})

export const getUser = createAsyncThunk('user/get', async () => {
    const response = await UserControllerService.getUser()
    return response
})

export const getUserById = createAsyncThunk('user/getById', async (id: string) => {
    const response = await UserControllerService.getUserById(id)
    return response
})

export const onboardUser = createAsyncThunk('user/onboard', async (args: any) => {
    const { coupon, user } = args
    const response = await UserControllerService.onboardWithCoupon(coupon, user)
    return response
})


export const { logout, updateNewUser, updateTimezone } = userSlice.actions

export const selectJWT = (state: RootState) => state.user.jwt

export default userSlice.reducer
