import { MenuItem, Select } from "@mui/material"
import { Foodstuff } from "../../models/Foodstuff"
import { useDispatch } from "react-redux"
import { updateConsumedMealFoodstuffWeight, updateMealFoodstuffMeasurementUnitsSelected } from "../../state/meals/mealSlice"
import { MeasurementUnit } from "../../models/MeasurementUnit"

export function MeasurementUnits(props: { foodstuff: Foodstuff }) {
    const { foodstuff } = props

    const dispatch = useDispatch<any>()

    function updateMeasurementUnitHandler(id: string, symbol: string) {
        dispatch(updateMealFoodstuffMeasurementUnitsSelected({id: id, symbol: symbol}))
    }

    return (<div>
        <Select
            name="measurementUnits"
            defaultValue={''}
            onChange={(e) => { updateMeasurementUnitHandler(foodstuff.id!!, e.target.value) }}
        >
            {foodstuff.measurementUnits?.map(mu => {
                return (<MenuItem value={mu.symbol}>{mu.name}</MenuItem>)
            })}
        </Select>
    </div>)
}



