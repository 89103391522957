import { useDispatch } from "react-redux";
import { Meal } from "../../models/Meal";
import { updateMealEditStatus } from "../../state/meals/mealSlice";
import { EditMeal } from "../meal/EditMeal";
import { Box, Typography } from "@mui/material";

export function MealtimeAssignement(props: { meals: AssigneableMeal[] }) {
    const { meals } = props
    const dispatch = useDispatch<any>()

    return (
        <div>
            {meals && (
                meals.find(m => m.isEdited) ? <EditMeal meal={meals.find(m => m.isEdited)!!} /> : null
            )}
            {
                meals ?
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap'
                    }}>
                        <Box sx={{ flex: 1, minWidth: '50%' }}>
                            <Typography fontWeight={'bold'}>Recepty</Typography>
                            {meals.filter(m => !m.isAssigned).map(meal => (<p key={meal.id} onClick={() => dispatch(updateMealEditStatus({
                                id: meal.id,
                                mealtime: meal.mealtime
                            }))}>{meal.name}</p>))}

                        </Box>
                        <Box sx={{ flex: 1, minWidth: '50%' }}>
                            <Typography fontWeight={'bold'}>Jedla klienta</Typography>
                            {meals.filter(m => m.isAssigned).map(meal => (<p key={meal.id} onClick={() => dispatch(updateMealEditStatus({
                                id: meal.id,
                                mealtime: meal.mealtime
                            }))}>{meal.name}</p>))}
                        </Box>
                    </Box> : null}
        </div >
    )
}

export interface AssigneableMeal extends Meal {
    isAssigned: boolean,
    isEdited: boolean
}