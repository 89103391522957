import { useEffect } from "react"

import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../app/store"
import { fetchMeals } from "../../state/meals/mealSlice"
import DietMealtimes from "./DietMealtimes"

export function Diet() {

    let meals = useSelector((state: RootState) => state.meal.meals)
    const dispatch = useDispatch<any>()

    useEffect(() => {
        dispatch(fetchMeals())
    }, [])

    let breakfastMeals = meals.filter(m => m.mealtime === 'BREAKFAST')
    let snackAmMeals = meals.filter(m => m.mealtime === 'SNACK_AM')
    let lunchMeals = meals.filter(m => m.mealtime === 'LUNCH')
    let snackPmMeals = meals.filter(m => m.mealtime === 'SNACK_PM')
    let dinnerMeals = meals.filter(m => m.mealtime === 'DINNER')

    return (

        <div>
            <DietMealtimes meals={breakfastMeals}></DietMealtimes>
            <DietMealtimes meals={snackAmMeals}></DietMealtimes>
            <DietMealtimes meals={lunchMeals}></DietMealtimes>
            <DietMealtimes meals={snackPmMeals}></DietMealtimes>
            <DietMealtimes meals={dinnerMeals}></DietMealtimes>
        </div>
    )
}
