import { Box, Typography } from "@mui/material";
import { Diet } from "../diet/Diet";
import { Clients } from "../home/clients/Clients";
import { Meals } from "../meal/Meals";

export function TrainerDashboard() {

    return (
        <Box p={5} style={{ background: '#EFE4E1', borderRadius: 10 }} sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Typography p={5} variant="h3">Moji klienti</Typography>
            <Clients />
            <Typography p={5}variant="h3">Jedlá</Typography>
            <Meals />
        </Box>)
}