import { Box, Typography } from "@mui/material";
import { Diet } from "../diet/Diet";
import { RecommendedMeals } from "../meal/RecommendedMeals";

export function ClientDashboard() {

    return (
        <Box p={5} style={{ background: '#EFE4E1', borderRadius: 10 }} sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <RecommendedMeals />
            <Diet />
        </Box>)
}