import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { ConsumedMeal, Foodstuff, FoodstuffControllerService, FoodstuffsCalculateRequestDTO, Meal, MealControllerService } from "../..";
import { RootState } from "../../app/store";
import { getUserById } from "../user/userSlice";
import { AssigneableMeal } from "../../features/diet/MealtimeAssignment";
import { getClientMeals } from "../clients/clientsSlice";

interface MealState {
    meals: Meal[]
    status: 'NEW' | 'LOADING' | 'OK' | 'ERROR'
    mealsDrafts: {
        meals: Record<string, AssigneableMeal[]>
        targetValues: Record<string, Record<string, number>>
        calculatedMealId: string
    },
    recommendedMeals: Meal[],
    foodstuff: {
        foodstuff: Foodstuff
        status: 'NEW' | 'LOADING' | 'OK' | 'ERROR'
        likeFoodstuffs: Foodstuff[]
    },
    foodstuffs: {
        foodstuffs: Foodstuff[]
    }
    meal: Meal,
    consumedMeal: {
        meal: ConsumedMeal
    },
    consumedMeals: ConsumedMeal[]
}

const initialState: MealState = {
    meals: [],
    status: 'NEW',
    mealsDrafts: {
        meals: {},
        targetValues: {
            "BREAKFAST": {
                "ENERGY": 363.15,
                "PROTEIN": 22.02,
                "CARBOHYDRATE": 34.4,
                "FAT": 14.01,
                "FIBRE": 6.6
            },
            "SNACK_AM": {
                "ENERGY": 363.15,
                "PROTEIN": 22.02,
                "CARBOHYDRATE": 34.4,
                "FAT": 14.01,
                "FIBRE": 6.6
            },
            "LUNCH": {
                "ENERGY": 363.15,
                "PROTEIN": 22.02,
                "CARBOHYDRATE": 34.4,
                "FAT": 14.01,
                "FIBRE": 6.6
            },
            "SNACK_PM": {
                "ENERGY": 363.15,
                "PROTEIN": 22.02,
                "CARBOHYDRATE": 34.4,
                "FAT": 14.01,
                "FIBRE": 6.6
            },
            "DINNER": {
                "ENERGY": 363.15,
                "PROTEIN": 22.02,
                "CARBOHYDRATE": 34.4,
                "FAT": 14.01,
                "FIBRE": 6.6
            }
        },
        calculatedMealId: ''
    },
    recommendedMeals: [],
    foodstuffs: {
        foodstuffs: []
    },
    foodstuff: {
        foodstuff: {
            name: "",
            density: 1,
            energyInKcal: 0,
            proteinMassFraction: 0,
            carbohydrateMassFraction: 0,
            fatMassFraction: 0,
            fibreMassFraction: 0,
            measurementUnits: []
        },
        status: 'NEW',
        likeFoodstuffs: []
    },
    meal: {
        languageCode: Meal.languageCode.SK,
        foodstuffs: [],
        mealtime: 'BREAKFAST'
    },
    consumedMeal: {
        meal: {
            foodstuffs: []
        }
    },
    consumedMeals: []
}

const mealSlice = createSlice({
    name: "meal",
    initialState,
    reducers: {
        updateFoodstuffWeight: (state, action) => {
            const { meal, foodstuff, value } = action.payload
            const matchedFoodstuff = Object.values(state.mealsDrafts.meals)
                .flatMap(f => f)
                .find(m => m.id == meal.id)?.foodstuffs?.find(fs => fs.id == foodstuff.id)
            if (matchedFoodstuff) {
                matchedFoodstuff.weight = value
            }
        },
        updateFoodstuffLock: (state, action) => {
            const { meal, foodstuff } = action.payload
            const matchedFoodstuff = Object.values(state.mealsDrafts.meals)
                .flatMap(f => f)
                .find(m => m.id == meal.id)?.foodstuffs?.find(fs => fs.id == foodstuff.id)
            if (matchedFoodstuff) {
                matchedFoodstuff.locked = !matchedFoodstuff.locked
            }
        },
        patchFoodstuff: (state, action) => {
            if (action.payload.name) {
                state.foodstuff.foodstuff.name = action.payload.name
            }
            if (action.payload.density) {
                state.foodstuff.foodstuff.density = action.payload.density
            }
            if (action.payload.energyInKcal) {
                state.foodstuff.foodstuff.energyInKcal = action.payload.energyInKcal
            }
            if (action.payload.carbohydrateMassFraction) {
                state.foodstuff.foodstuff.carbohydrateMassFraction = action.payload.carbohydrateMassFraction
            }
            if (action.payload.proteinMassFraction) {
                state.foodstuff.foodstuff.proteinMassFraction = action.payload.proteinMassFraction
            }
            if (action.payload.fatMassFraction) {
                state.foodstuff.foodstuff.fatMassFraction = action.payload.fatMassFraction
            }
            if (action.payload.fibreMassFraction) {
                state.foodstuff.foodstuff.fibreMassFraction = action.payload.fibreMassFraction
            }
            if (action.payload.measurementUnits) {
                state.foodstuff.foodstuff.measurementUnits = action.payload.measurementUnits
            }
        },
        updateConsumedMealFoodstuffWeight: (state, action) => {
            const { foodstuffId, weight } = action.payload
            state.consumedMeal.meal.foodstuffs!!.find(fs => fs.id == foodstuffId)!!.weight = weight
        },
        updateConsumedMealName: (state, action) => {
            state.consumedMeal.meal.name = action.payload.name
        },
        updateConsumedMealMealtime: (state, action) => {
            state.consumedMeal.meal.mealtime = action.payload.mealtime
        },
        updateTargetValue: (state, action) => {
            const { mealtime, targetKey, value } = action.payload
            state.mealsDrafts.targetValues[mealtime][targetKey] = value
        },
        updateCalculatedMealId: (state, action) => {
            state.mealsDrafts.calculatedMealId = action.payload
        },
        deleteDraftMeal: (state, action) => {
            let { meal } = action.payload
            state.mealsDrafts.meals[meal.mealtime] = state.mealsDrafts.meals[meal.mealtime].filter(m => m.id != meal.id)
        },
        addFoodstuffToMeal: (state, action) => {
            state.meal.foodstuffs!!.push(action.payload)
        },
        updateMealFoodstuffWeight: (state, action) => {
            const { id, weight } = action.payload
            state.meal.foodstuffs!!.find(fs => fs.id == id)!!.weight!! = weight
        },
        updateMealFoodstuffValue: (state, action) => {
            const { id, value } = action.payload
            const matched = state.meal.foodstuffs!!.find(fs => fs.id == id)!!
            matched.value = value
            const weight = value * (matched.measurementUnits?.find(mu => mu.selected)?.conversionFactor || 1)
            matched.weight = weight
        },
        updateMealFoodstuffMeasurementUnits: (state, action) => {
            const { id, measurementUnits } = action.payload
            const matched = state.meal.foodstuffs!!.find(fs => fs.id == id)!!
            matched.measurementUnits = measurementUnits
        },
        updateMealFoodstuffMeasurementUnitsSelected: (state, action) => {
            const { id, symbol } = action.payload
            const matched = state.meal.foodstuffs!!.find(fs => fs.id == id)!!
            let conversionFactor = 0.0
            matched.measurementUnits!!.forEach(mu => {
                mu.selected = mu.symbol == symbol
                if (mu.symbol == symbol) {
                    conversionFactor = mu.conversionFactor
                }
            })
            matched.weight = (matched.value || 0) * conversionFactor

        },
        deleteMealFoodstuff: (state, action) => {
            state.meal.foodstuffs = state.meal.foodstuffs?.filter(fs => fs.id != action.payload)
        },
        patchMeal: (state, action) => {
            const meal: Meal = action.payload
            if (meal.name) {
                state.meal.name = meal.name
            }
            if (meal.receipt) {
                state.meal.receipt = meal.receipt
            }
            if (meal.mealtime) {
                state.meal.mealtime = meal.mealtime
            }
        },
        setLikeFoodstuffs: (state, action) => {
            state.foodstuff.likeFoodstuffs = action.payload
        },
        setConsumedMeal: (state, action) => {
            state.consumedMeal.meal = action.payload
        },
        updateMealEditStatus: (state, action) => {
            const { id, mealtime } = action.payload
            const keys = Object.keys(state.mealsDrafts.meals)
            state.mealsDrafts.meals[mealtime].forEach(m => {
                m.isEdited = m.id == id
            })
        },
        setUserMealAssigned: (state, action) => {
            const { userMealId, isAssigned } = action.payload
            const matched = Object.values(state.mealsDrafts.meals).flat().find(m => m.id!! == userMealId)
            if (matched) {
                matched.isAssigned = isAssigned
            }
        }
    },
    extraReducers(builder) {
        builder.addCase(fetchMeals.pending, (state, _action) => {
            state.status = 'LOADING'
        })
            .addCase(fetchMeals.fulfilled, (state, action) => {
                state.status = 'OK'
                const mapped = action.payload.map(m => {
                    return ({
                        ...m,
                        totalEnergy: calculateTotalKcal(m),
                        totalProtein: calculateTotalProtein(m),
                        totalCarbohydrate: calculateTotalCarbohydrate(m),
                        totalFat: calculateTotalFat(m),
                        totalFiber: calculateTotalFiber(m)

                    })
                })
                state.meals = mapped
                const existingIds = Object.values(state.mealsDrafts.meals).flat().map(m => m.id!!)
                action.payload.forEach(m => {
                    const match = existingIds.find(id => m.id!! == id)
                    if (!match) {
                        const assigneableMeal: AssigneableMeal = {...m, isAssigned: false, isEdited: false}
                        if (state.mealsDrafts.meals[m.mealtime!!] == undefined) {
                            state.mealsDrafts.meals[m.mealtime!!] = []
                        }
                        state.mealsDrafts.meals[m.mealtime!!].push(assigneableMeal)
                    }
                })
            })
            .addCase(fetchMeals.rejected, (state) => {
                state.status = 'ERROR'
            })
            .addCase(fetchMealsDraft.fulfilled, (state, action) => {
                const existingIds = Object.values(state.mealsDrafts.meals).flat().map(m => m.id!!)
                const draftMeals: Record<string, AssigneableMeal[]> = {}
                action.payload.forEach((meal) => {
                    let { mealtime, ...rest } = meal
                    if (!draftMeals[mealtime!!]) {
                        draftMeals[mealtime!!] = []
                    }
                    draftMeals[mealtime!!].push({
                        mealtime,
                        isAssigned: existingIds.includes(meal.id!!),
                        isEdited: false,
                        ...rest
                    })
                })
                state.mealsDrafts.meals = draftMeals
            }).addCase(calculateFoodstuffs.fulfilled, (state, action) => {
                const [mealtime, mealId] = state.mealsDrafts.calculatedMealId.split('|')
                state.mealsDrafts.meals[mealtime].find(m => m.id == mealId)!!.foodstuffs = action.payload
            }).addCase(saveMealForTrainee.fulfilled, (state, action) => {
                const { id, mealtime } = action.meta.arg
                state.mealsDrafts.meals[mealtime!!].find(m => m.id!! == id)!!.isAssigned = true
                state.mealsDrafts.meals[mealtime!!].find(m => m.id!! == id)!!.isEdited = false
            })
            .addCase(saveFoodstuff.pending, (state, _action) => {
                state.foodstuff.status = "LOADING"
            })
            .addCase(saveFoodstuff.fulfilled, (state, _action) => {
                state.foodstuff.foodstuff = {
                    name: "",
                    carbohydrateMassFraction: 0,
                    energyInKcal: 0,
                    fibreMassFraction: 0,
                    fatMassFraction: 0,
                    proteinMassFraction: 0
                }
                state.foodstuff.status = 'OK'
            })
            .addCase(getLikeFoodstuffs.fulfilled, (state, action) => {
                state.foodstuff.likeFoodstuffs = action.payload
            })
            .addCase(saveMeal.fulfilled, (state, action) => {
                state.meal = {
                    name: '',
                    receipt: '',
                    languageCode: Meal.languageCode.SK,
                    foodstuffs: []
                }
            })
            .addCase(fetchMeal.fulfilled, (state, action) => {
                state.meal = action.payload
            })
            .addCase(fetchFoodstuffs.fulfilled, (state, action) => {
                state.foodstuffs.foodstuffs = action.payload.sort((fs1, fs2) => fs1.name!!.localeCompare(fs2.name!!))
            })
            .addCase(saveConsumedMeal.fulfilled, (state, action) => {
                state.consumedMeal.meal = {
                    name: '',
                    foodstuffs: []
                }
                state.consumedMeals.push(action.payload)
            })
            .addCase(getConsumedMeals.fulfilled, (state, action) => {
                state.consumedMeals = action.payload
            })
            .addCase(getUserById.fulfilled, (state, action) => {
                ['BREAKFAST', 'SNACK_AM', 'LUNCH', 'SNACK_PM', 'DINNER'].forEach(mealtime => {
                    ['ENERGY', 'PROTEIN', 'CARBOHYDRATE', 'FAT'].forEach(keyName => {
                        state.mealsDrafts.targetValues!![mealtime][keyName] = action.payload.mealtimeIntake!![mealtime][keyName]
                    })
                })
            })
            .addCase(recommendMeals.fulfilled, (state, action) => {
                state.recommendedMeals = action.payload
            })
            .addCase(deleteUserMeal.fulfilled, (state, action) => {
                const { userMealId } = action.meta.arg
                const mealToDelete = Object.values(state.mealsDrafts.meals).flat().find(m => m.id!! == userMealId)!!
                mealToDelete.isAssigned = false
                mealToDelete.isEdited = false
            })
            .addCase(getClientMeals.fulfilled, (state, action) => {
                const ids = action.payload.map(m => m.id!!)!!
                Object.values(state.mealsDrafts.meals).flat().filter(m => ids.some(i => m.id!! = i))!!
                state.mealsDrafts.meals = groupMealsByMealtime(action.payload)
            })
    },
})

export const fetchMeals = createAsyncThunk('meal/fetchMeals', async () => {
    const response = await MealControllerService.getAllMeals()
    return response
})

export const fetchMealsDraft = createAsyncThunk('meal/fetchMealsDraft', async (targetValues: Record<string, Record<string, number>>) => {
    const response = await MealControllerService.calculateMealsWeights({
        targetValues: targetValues
    })
    return response
})

export const calculateFoodstuffs = createAsyncThunk('meal/calculateFoodstuffs', async (request: FoodstuffsCalculateRequestDTO) => {
    const response = await FoodstuffControllerService.calculateFoodstuff({
        foodstuffs: request.foodstuffs,
        targetValues: request.targetValues
    })
    return response
})

export const saveMealForTrainee = createAsyncThunk('/meal/saveMealForTrainee', async (meal: Meal, { getState }) => {
    const currentState = getState() as RootState
    const clientId = currentState.client.currentClient?.id
    const response = await MealControllerService.saveUserMeal(clientId!!, meal)
    return response
})

export const saveFoodstuff = createAsyncThunk('/meal/saveFoodstuff', async (foodsdtuff: Foodstuff) => {
    const response = await FoodstuffControllerService.createFoodstuff(foodsdtuff)
    return response
})

export const getLikeFoodstuffs = createAsyncThunk('/meal/getLikeFoodstuffs', async (contains: string) => {
    const response = await FoodstuffControllerService.getLikeFoodstuffs(contains)
    return response
})

export const saveMeal = createAsyncThunk('/meal/saveMeal', async (meal: Meal) => {
    const response = await MealControllerService.saveMeal(meal)
    return response
})

export const fetchMeal = createAsyncThunk('/meal/fetchMeal', async (mealId: string) => {
    const response = await MealControllerService.getMeal(mealId)
    return response
})

export const fetchFoodstuffs = createAsyncThunk('/meal/fetchFoodstuffs', async () => {
    const response = await FoodstuffControllerService.getAllFoodstuffs()
    return response
})

export const saveConsumedMeal = createAsyncThunk('/meal/saveConsumedMeal', async (meal: ConsumedMeal) => {
    const response = await MealControllerService.saveConsumedMeal(meal)
    return response
})

export const getConsumedMeals = createAsyncThunk('/meal/getConsumedMeals', async () => {
    const response = await MealControllerService.getConsumedMeals()
    return response
})

export const recommendMeals = createAsyncThunk('/meal/recommendMeals', async () => {
    const response = await MealControllerService.recommendMeals()
    return response
})

export const deleteUserMeal = createAsyncThunk('/meal/deleteUserMeal', async (args: any) => {
    const { userId, userMealId } = args
    const response = await MealControllerService.deleteUserMeal(userMealId, userId)
})


export const selectMeals = (state: RootState) => state.meal

export const { updateFoodstuffWeight, updateFoodstuffLock, patchFoodstuff, updateConsumedMealFoodstuffWeight, updateConsumedMealName, updateConsumedMealMealtime, updateTargetValue, updateCalculatedMealId,
    deleteDraftMeal, addFoodstuffToMeal, updateMealFoodstuffWeight, updateMealFoodstuffValue, updateMealFoodstuffMeasurementUnits, updateMealFoodstuffMeasurementUnitsSelected, deleteMealFoodstuff, patchMeal, setLikeFoodstuffs, setConsumedMeal, updateMealEditStatus, setUserMealAssigned } = mealSlice.actions


export default mealSlice.reducer

function calculateTotalKcal(meal: Meal): number {
    return meal.foodstuffs?.reduce((acc, cur) => {
        const a = acc + (cur.energyInKcal || 0) * cur.weight!!
        acc = acc + (cur.energyInKcal || 0) * cur.weight!!
        return acc
    }, 0) || 0
}

function calculateTotalProtein(meal: Meal): number {
    return meal.foodstuffs?.reduce((acc, cur) => {
        acc = acc + (cur.proteinMassFraction || 0) * cur.weight!!
        return acc
    }, 0) || 0
}

function calculateTotalFat(meal: Meal): number {
    return meal.foodstuffs?.reduce((acc, cur) => {
        acc = acc + (cur.fatMassFraction || 0) * cur.weight!!
        return acc
    }, 0) || 0
}


function calculateTotalCarbohydrate(meal: Meal): number {
    return meal.foodstuffs?.reduce((acc, cur) => {
        acc = acc + (cur.carbohydrateMassFraction || 0) * cur.weight!!
        return acc
    }, 0) || 0
}

function calculateTotalFiber(meal: Meal): number {
    return meal.foodstuffs?.reduce((acc, cur) => {
        acc = acc + (cur.fibreMassFraction || 0) * cur.weight!!
        return acc || 0
    }, 0) || 0
}


function groupMealsByMealtime(meals: Meal[]): Record<string, AssigneableMeal[]> {
    return meals.reduce<Record<string, AssigneableMeal[]>>((acc, meal) => {
        const { mealtime } = meal
        if (!acc[mealtime!!]) {
            acc[mealtime!!] = [];
        }
        let assignableMeal: AssigneableMeal = { ...meal, isAssigned: true, isEdited: false }
        acc[mealtime!!].push(assignableMeal);
        return acc;
    }, {});
}