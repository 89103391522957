import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { addTrainee, fetchTrainees } from "../../../state/clients/clientsSlice"
import { RootState } from "../../../app/store"
import { Box, Button, Card, CardContent, Link, MenuItem, Select, TextField, Typography } from "@mui/material"
import { BrowserRouter as Router, Link as RouterLink } from "react-router-dom"
import { BodyMeasurements, User } from "../../.."
import { onboardUser, updateNewUser } from "../../../state/user/userSlice"
import { NewClient } from "../../user/NewClient"

export function Clients() {

    const dispatch = useDispatch<any>()
    let clients = useSelector((state: RootState) => state.client)
    let user = useSelector((state: RootState) => state.user)

    useEffect(() => {
        dispatch(fetchTrainees())
    }, [])

    const [displayAddUserForm, setDisplayAddUserForm] = useState(false)

    const toggleDisplayAddUserForm = () => {
        setDisplayAddUserForm(!displayAddUserForm)
    }

    const updateClientHandler = (name: string, value: any) => {
        dispatch(updateNewUser({
            name: name,
            value: value
        }))
    }

    const newClientSubmittedHandler = () => {
        dispatch(addTrainee(user.newUser))
    }

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
        }}>
            <Button onClick={() => toggleDisplayAddUserForm()}>Pridať klienta</Button>

            {displayAddUserForm &&
                <NewClient coupon={""} submitHandler={newClientSubmittedHandler}></NewClient>
            }

            {clients.clients.map((client) => {
                return (
                    <Box key={client.id}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent>
                                <Typography variant="h5" component="div">
                                    <Link key={client.id} component={RouterLink} to={`/trainee/${client.id}`}>{client.username}</Link>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Box>
                )
            })}</Box>)
}