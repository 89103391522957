import { Box, Button, Input, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { AssigneableMeal } from "../diet/MealtimeAssignment";
import { deleteUserMeal, saveMealForTrainee, updateFoodstuffLock, updateFoodstuffWeight } from "../../state/meals/mealSlice";
import { calculateValues } from "../diet/DietService";
import { useDispatch, useSelector } from "react-redux";
import { Meal } from "../../models/Meal";
import { RootState } from "../../app/store";
import { Foodstuff } from "../../models/Foodstuff";
import LockOpenIcon from '@mui/icons-material/LockOpen'
import LockIcon from '@mui/icons-material/Lock'
import DeleteIcon from '@mui/icons-material/Delete'
import CalculateIcon from '@mui/icons-material/Calculate'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'

export function EditMeal(params: { meal: AssigneableMeal }) {
    const { meal } = params
    const dispatch = useDispatch<any>()
    let mealsDrafts = useSelector((state: RootState) => state.meal.mealsDrafts)

    const calculateBackgroundColor = (meal: Meal, valueType: string): string => {
        let value = calculateValues(meal)[valueType] / mealsDrafts.targetValues[meal.mealtime!!][valueType]!!
        if (value < 0.85) {
            return '#100ea5'
        } else if (value < 0.95) {
            return '#3ca7d9'
        } else if (value > 1.15) {
            return '#e7e40e'
        } else if (value > 1.05) {
            return "#de362e"
        }
        else {
            return "#ffffff"
        }

    }

    function toggleFoodstuffLockHandler(meal: Meal, foodstuff: Foodstuff) {
        dispatch(updateFoodstuffLock({ meal, foodstuff }))
    }

    function updateFoodstuffWeightHandler(meal: Meal, foodstuff: Foodstuff, value: string) {
        const numericValue = parseFloat(value)
        if (isNaN(numericValue)) {
            dispatch(updateFoodstuffWeight({ meal, foodstuff, value: 0 }))
        }
        else {
            dispatch(updateFoodstuffWeight({ meal, foodstuff, value: numericValue }))
        }
    }

    const calculatePercentageDifference = (meal: Meal, valueType: string) => {
        return Number(100 * calculateValues(meal)[valueType] / mealsDrafts.targetValues[meal.mealtime!!][valueType]!! - 100).toFixed(1)
    }

    const unassignMealHandler = (id: string) => {
        dispatch(deleteUserMeal({
            userId: '99fbb200-dffe-4792-a8a4-297ce41571b4',
            userMealId: id
        }))
    }

    const calculateDraftMealHandler = (meal: Meal) => {
        // dispatch(calculateFoodstuffs())
    }

    function saveFoodstuffsHandler(meal: Meal): void {
        dispatch(saveMealForTrainee(meal))
    }

    return (
        <Box>
            <Box key={meal.id}
                m={10} sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexFlow: 'column',
                    justifyContent: 'center'
                }}>
                <Typography variant="h4" >{meal.name}</Typography>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow key='header'>
                                <TableCell></TableCell>
                                <TableCell>Potravina</TableCell>
                                <TableCell align="right">Hmotnost [g]</TableCell>
                                <TableCell align="right">Kalorie [kcal]</TableCell>
                                <TableCell align="right">Tuk [g]</TableCell>
                                <TableCell align="right">Sacharidy [g]</TableCell>
                                <TableCell align="right">Protein [g]</TableCell>
                                <TableCell align="right">Vlaknina [g]</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {meal.foodstuffs?.map((row) => (
                                <TableRow
                                    key={`${meal.id}-${row.id}`}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell onClick={(e) => toggleFoodstuffLockHandler(meal, row)}>{row.locked ? <LockIcon /> : <LockOpenIcon />}</TableCell>
                                    <TableCell component="th" scope="row">{row.name}</TableCell>
                                    <TableCell align="right">
                                        <Input value={row.weight || 0} onChange={(e) => updateFoodstuffWeightHandler(meal, row, e.target.value)} disabled={row.locked} />
                                    </TableCell>
                                    <TableCell align="right">{((row.energyInKcal || 0) * (row.weight || 0)).toFixed(2)}</TableCell>
                                    <TableCell align="right">{((row.fatMassFraction || 0) * (row.weight || 0)).toFixed(2)}</TableCell>
                                    <TableCell align="right">{((row.carbohydrateMassFraction || 0) * (row.weight || 0)).toFixed(2)}</TableCell>
                                    <TableCell align="right">{((row.proteinMassFraction || 0) * (row.weight || 0)).toFixed(2)}</TableCell >
                                    <TableCell align="right">{((row.fibreMassFraction || 0) * (row.weight || 0)).toFixed(2)}</TableCell>
                                </TableRow>
                            ))}
                            <TableRow key='sum'>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell align="right"></TableCell>
                                <TableCell align="right" sx={{
                                    backgroundColor: calculateBackgroundColor(meal, 'ENERGY')

                                }}>{calculateValues(meal)['ENERGY'].toFixed(2)} ({calculatePercentageDifference(meal, 'ENERGY')} %)</TableCell>
                                <TableCell align="right" sx={{
                                    backgroundColor: calculateBackgroundColor(meal, 'FAT')
                                }}>{calculateValues(meal)['FAT'].toFixed(2)} ({calculatePercentageDifference(meal, 'FAT')} %)</TableCell>
                                <TableCell align="right" sx={{
                                    backgroundColor: calculateBackgroundColor(meal, 'CARBOHYDRATE')
                                }}>{calculateValues(meal)['CARBOHYDRATE'].toFixed(2)} ({calculatePercentageDifference(meal, 'CARBOHYDRATE')} %) </TableCell>
                                <TableCell align="right" sx={{
                                    backgroundColor: calculateBackgroundColor(meal, 'PROTEIN')
                                }}>{calculateValues(meal)['PROTEIN'].toFixed(2)} ({calculatePercentageDifference(meal, 'PROTEIN')} %)</TableCell>
                                <TableCell align="right" sx={{
                                    backgroundColor: calculateBackgroundColor(meal, 'FIBRE')
                                }}>{calculateValues(meal)['FIBRE'].toFixed(2)} ({calculatePercentageDifference(meal, 'FIBRE')} %)</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }} aria-label="outlined primary button group">

                    <Button variant="contained" color="error" startIcon={<DeleteIcon />} onClick={() => unassignMealHandler(meal.id!!)}>
                        Zmazať
                    </Button>
                    <Button variant="contained" startIcon={<CalculateIcon />} onClick={() => calculateDraftMealHandler(meal)}>
                        Prepočítať
                    </Button>
                    <Button variant="contained" startIcon={<PlaylistAddCheckIcon />} onClick={() => saveFoodstuffsHandler(meal)}>
                        Uložit pre klienta
                    </Button>
                    {/* <Button variant="contained" startIcon={<CalculateIcon />} onClick={() => calculateFoodstuffsHandler(meal)}>
                        Prepočítať
                    </Button>
                    <Button variant="contained" startIcon={<PlaylistAddCheckIcon />} onClick={() => saveFoodstuffsHandler(meal)}>
                        Uložit pre klienta
                    </Button> */}
                </Box>
            </Box>
        </Box>

    )

}