import { Box, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { RootState } from "../../app/store";
import { fetchMeal } from "../../state/meals/mealSlice";
import { useParams } from "react-router";

export function Meal() {
  let foodstuffStatus = useSelector((state: RootState) => state.meal.foodstuff.status)
  const [snackbarOpen, setSnackbarOpen] = useState(false)


  const { mealId } = useParams()

  const meal = useSelector((state: RootState) => state.meal)

  const dispatch = useDispatch<any>()

  useEffect(() => {
    dispatch(fetchMeal(mealId!!))
  }, [])


  const handleClose = () => {
    setSnackbarOpen(false)
  };

  const getSumValues = () => {
    return {
      "ENERGY": meal.meal.foodstuffs?.reduce((acc, fs) => {
        return acc + (fs.weight || 0) * (fs.energyInKcal || 0)
      }, 0),
      "PROTEIN": meal.meal.foodstuffs?.reduce((acc, fs) => {
        return acc + (fs.weight || 0) * (fs.proteinMassFraction || 0)
      }, 0),
      "FAT": meal.meal.foodstuffs?.reduce((acc, fs) => {
        return acc + (fs.weight || 0) * (fs.fatMassFraction || 0)
      }, 0),
      "CARBOHYDRATE": meal.meal.foodstuffs?.reduce((acc, fs) => {
        return acc + (fs.weight || 0) * (fs.carbohydrateMassFraction || 0)
      }, 0),
      "FIBRE": meal.meal.foodstuffs?.reduce((acc, fs) => {
        return acc + (fs.weight || 0) * (fs.fibreMassFraction || 0)
      }, 0)
    }
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <Typography variant="h1">{meal.meal.name}</Typography>

      {meal.meal.foodstuffs?.map(fs => {
        return (
          <Typography variant="h5">{fs.name} {fs.weight}g</Typography>
        )
      })}

      <Typography variant="h4">{meal.meal.receipt}</Typography>
      <Typography variant="h3">Energie {getSumValues()['ENERGY']?.toFixed(1)} kcal</Typography>
      <Typography variant="h3">Bielkoviny {getSumValues()['PROTEIN']?.toFixed(1)} g</Typography>
      <Typography variant="h3">Tuk {getSumValues()['FAT']?.toFixed(1)} g</Typography>
      <Typography variant="h3">Sacharidy {getSumValues()['CARBOHYDRATE']?.toFixed(1)} kcal</Typography>
      <Typography variant="h3">Vlaknina {getSumValues()['FIBRE']?.toFixed(1)} kcal</Typography>
    </Box>
  )
}
