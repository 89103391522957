/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type BodyMeasurements = {
    weight?: number;
    height?: number;
    chest?: number;
    waist?: number;
    hips?: number;
    buttocks?: number;
    thigh?: number;
    calf?: number;
    type?: BodyMeasurements.type;
    date?: string;
    targetDate?: string;
};

export namespace BodyMeasurements {

    export enum type {
        ACTUAL = 'ACTUAL',
        PLAN = 'PLAN',
    }


}

