
import MenuIcon from '@mui/icons-material/Menu'
import HomeIcon from '@mui/icons-material/Home'
import LogoutIcon from '@mui/icons-material/Logout'
import React, { useState } from "react"
import { Box, AppBar, Toolbar, IconButton, Menu, MenuItem, Button } from "@mui/material"
import { useLocation, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../app/store"
import { logout } from "../state/user/userSlice"

export function MobileLayout({ children }: any) {

    const navigate = useNavigate()
    const dispatch = useDispatch<any>()
    const { pathname } = useLocation()
    const userId = pathname.match('/user/(.*)/')?.at(1)

    let user = useSelector((state: RootState) => state.user)


    const enum ACTIONS {
        Diet,
        How,
        Bio,
        DietPlan,
        Meals
    }

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleDialogClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    };
    const handleClose = () => {
        setAnchorEl(null)
    };
    const handleClick = (action: ACTIONS) => {
        setAnchorEl(null)
        switch (action) {
            case ACTIONS.Diet: {
                navigate(`/user/${user.user!!.id}/diet`)
                break
            }
            case ACTIONS.How: {
                navigate(`/user/${userId}/tips`)
                break
            }
            case ACTIONS.Bio: {
                navigate(`/user/${userId}/bio`)
                break
            }
            case ACTIONS.Meals: {
                navigate(`/user/${userId}/meals`)
                break
            }

        }
    }

    return (
        <Box>
            {user.user != null &&
                <AppBar position="static" sx={{
                    backgroundColor: '#7f4f24',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around'
                }}>

                    <Toolbar>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleDialogClick}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Button color="info" onClick={() => navigate(`/user/${user.user?.id}/home`)} ><HomeIcon /></Button>
                        <Button color="error" onClick={() => {
                            dispatch(logout())
                            navigate('/')
                        }
                        }>
                            <LogoutIcon />
                        </Button>
                    </Toolbar>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={() => handleClick(ACTIONS.How)}>Ako na to?</MenuItem>
                        <MenuItem onClick={() => handleClick(ACTIONS.Bio)}>Miery</MenuItem>
                        <MenuItem onClick={() => handleClick(ACTIONS.Diet)}>Jedalnicek</MenuItem>

                        {user.user.roles?.includes('TRAINER') &&
                            <Box>
                                <MenuItem onClick={() => handleClick(ACTIONS.Meals)}>Pokrmy</MenuItem>
                            </Box>
                        }
                    </Menu>

                </AppBar>}
            {children}
        </Box>
    )
}
