import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Typography } from "@mui/material";
import ExpandIcon from '@mui/icons-material/Expand'
import { Meal } from "../..";
import { useDispatch } from "react-redux";
import { getConsumedMeals, setConsumedMeal } from "../../state/meals/mealSlice";
import { useNavigate } from "react-router";

function DietMealtimes(props: { meals: Meal[] }) {
    const { meals } = props;
    enum Mealtime {
        BREAKFAST = 'RAŇAJKY',
        SNACK_AM = 'DESIATA',
        LUNCH = 'OBED',
        SNACK_PM = 'OLOVRANT',
        DINNER = 'VEČERA'
    }

    const dispatch = useDispatch<any>()
    const navigate = useNavigate()

    const mealtime = meals[0]?.mealtime as keyof typeof Mealtime

    const consumedMealHandler = (meal: Meal) => {
        dispatch(setConsumedMeal(meal))
        navigate('/meal/consumed')
    }

    return (
        <Box>
            {meals.length > 0 &&
                <Accordion sx={{
                    backgroundColor: "#EFE4E1"
                }}>
                    <AccordionSummary
                        expandIcon={<ExpandIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column'
                        }}
                    >
                        <Typography variant="h4" fontWeight='fontWeightBold'>{Mealtime[mealtime]}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {meals.map(m => {
                            return (
                                <Accordion key={m.id}>
                                    <AccordionSummary
                                        expandIcon={<ExpandIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography variant="h4">{m.name}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Box>
                                            {m.foodstuffs!!.map((fs, i) => {
                                                return (
                                                    <Typography key={i}>{fs.name} {fs.weight} g</Typography>
                                                )
                                            })}
                                            <p>{m.receipt}</p>
                                        </Box>
                                        <Button variant="contained" color="info" onClick={()=>{consumedMealHandler(m)}}>Zjedol som</Button>
                                        {m.imageIds?.map((image,i) => {
                                            return (
                                                <Box key={i}>
                                                    <img src={`http://localhost:8090/meals/id/${m.id}/image/id/${image}`} />
                                                </Box>)
                                        })}

                                    </AccordionDetails>
                                </Accordion>

                            )
                        })}
                    </AccordionDetails>
                </Accordion>}
        </Box>
    )
}

export default DietMealtimes