import { PieChart, Pie, Cell, ResponsiveContainer, Sector } from 'recharts';

export function Gauge(props: any) {

    const RADIAN = Math.PI / 180;
    const data = [
        { name: 'under', value: 95, color: '#f7de4d' },
        { name: 'optimum', value: 10, color: '#84bf6c' },
        { name: 'over', value: 25, color: '#b72d2a' }
    ];
    const cx = 150;
    const cy = 200;
    const iR = 50;
    const oR = 100;
    const { value } = props;

    const needle = (value: number, data: { name: string, value: number, color: string }[], cx: number, cy: number, iR: number, oR: number, color: string) => {
        let total = 0;
        data.forEach((v) => {
            total += v.value;
        });
        const ang = 180.0 * (1 - value / total);
        const length = (iR + 2 * oR) / 3;
        const sin = Math.sin(-RADIAN * ang);
        const cos = Math.cos(-RADIAN * ang);
        const r = 5;
        const x0 = cx + 5;
        const y0 = cy + 5;
        const xba = x0 + r * sin;
        const yba = y0 - r * cos;
        const xbb = x0 - r * sin;
        const ybb = y0 + r * cos;
        const xp = x0 + length * cos;
        const yp = y0 + length * sin;

        return [
            <circle cx={x0} cy={y0} r={r} fill={color} stroke="none" />,
            <path d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`} stroke="#none" fill={'black'} />,
        ];
    };

    return (

        <PieChart width={400} height={500}>
            <Pie
                dataKey="value"
                startAngle={180}
                endAngle={0}
                data={data}
                cx={cx}
                cy={cy}
                innerRadius={iR}
                outerRadius={oR}
                fill="#8884d8"
                stroke="none"
            >
                {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
            </Pie>
            {needle(value, data, cx, cy, iR, oR, '#d0d000')}
        </PieChart>

    );
};