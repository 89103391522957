/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Foodstuff } from './Foodstuff';

export type Meal = {
    id?: string;
    name?: string;
    receipt?: string;
    mealtime?: string;
    foodstuffs?: Array<Foodstuff>;
    imageIds?: Array<string>;
    languageCode: Meal.languageCode;
    tags?: Array<string>;
};

export namespace Meal {

    export enum languageCode {
        EN = 'EN',
        CS = 'CS',
        SK = 'SK',
    }


}

