import "./App.css"
import theme from "./common/ColorPalette"
import { BrowserRouter, Routes, Route } from "react-router-dom"

import { Diet } from "./features/diet/Diet"
import { Home } from "./features/home/Home"
import Box from "@mui/material/Box"
import { MobileLayout } from "./layout/MobileLayout"
import { DietTips } from "./features/diet/DietTips"
import { Bio } from "./features/bio/Bio"
import { Login } from "./features/login/Login"
import { Trainee } from "./features/trainee/Trainee"
import { Meals } from "./features/meal/Meals"
import { Meal } from "./features/meal/Meal"
import { ThemeProvider } from "@emotion/react"
import { ConsumedMeal } from "./features/meal/ConsumedMeal"
import { Onboarding } from "./features/user/Onboarding"



function App() {
  return (
    <div>
      <Box sx={{
        display: {
          // xs: 'block', sm: 'none', md: 'none', lg: 'none'
        }
      }}>
        <BrowserRouter>
          <MobileLayout>
            <ThemeProvider theme={theme}>
              <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/user/:userId/home" element={<Home />} />
                <Route path="/user/:userId/bio" element={<Bio />} />
                <Route path="/user/:userId/diet" element={<Diet />} />
                <Route path="/user/:userId/tips" element={<DietTips />} />
                <Route path="/trainee/:traineeId" element={<Trainee />} />
                <Route path="/user/:userId/meals" element={<Meals />} />
                <Route path="/meal/:mealId" element={<Meal />} />
                <Route path="/meal/consumed" element={<ConsumedMeal />} />
                <Route path="/coupon/:coupon" element={<Onboarding />} /> 
              </Routes>
            </ThemeProvider>
          </MobileLayout>
        </BrowserRouter>
      </Box>
    </div>
  )
}
export default App
