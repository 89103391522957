/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConsumedMeal } from '../models/ConsumedMeal';
import type { Meal } from '../models/Meal';
import type { MealCalculateRequestDTO } from '../models/MealCalculateRequestDTO';
import type { NormalizeMealRequest } from '../models/NormalizeMealRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MealControllerService {

    /**
     * @returns Meal OK
     * @throws ApiError
     */
    public static getAllMeals(): CancelablePromise<Array<Meal>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/meals',
        });
    }

    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static saveMeal(
        requestBody: Meal,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/meals',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Meal OK
     * @throws ApiError
     */
    public static recommendMeals(): CancelablePromise<Array<Meal>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/meals/recommend',
        });
    }

    /**
     * @param requestBody
     * @returns Meal OK
     * @throws ApiError
     */
    public static normalizeMeal(
        requestBody: NormalizeMealRequest,
    ): CancelablePromise<Meal> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/meals/normalize',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ConsumedMeal OK
     * @throws ApiError
     */
    public static getConsumedMeals(): CancelablePromise<Array<ConsumedMeal>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/meals/consumed',
        });
    }

    /**
     * @param requestBody
     * @returns ConsumedMeal OK
     * @throws ApiError
     */
    public static saveConsumedMeal(
        requestBody: ConsumedMeal,
    ): CancelablePromise<ConsumedMeal> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/meals/consumed',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param clientId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static saveUserMeal(
        clientId: string,
        requestBody: Meal,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/meals/client/{clientId}',
            path: {
                'clientId': clientId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns Meal OK
     * @throws ApiError
     */
    public static calculateMealsWeights(
        requestBody: MealCalculateRequestDTO,
    ): CancelablePromise<Array<Meal>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/meals/calculate',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param mealId
     * @param imageId
     * @returns string OK
     * @throws ApiError
     */
    public static getMealImage(
        mealId: string,
        imageId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/meals/id/{mealId}/image/id/{imageId}',
            path: {
                'mealId': mealId,
                'imageId': imageId,
            },
        });
    }

    /**
     * @param id
     * @returns Meal OK
     * @throws ApiError
     */
    public static getMeal(
        id: string,
    ): CancelablePromise<Meal> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/meals/id/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteMeal(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/meals/id/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns Meal OK
     * @throws ApiError
     */
    public static getAllMealsForClient(
        id: string,
    ): CancelablePromise<Array<Meal>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/meals/client/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @param userId
     * @returns any OK
     * @throws ApiError
     */
    public static deleteUserMeal(
        id: string,
        userId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/meals/user/{userId}/usermeal/{id}',
            path: {
                'id': id,
                'userId': userId,
            },
        });
    }

}
