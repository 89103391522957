import { Meal } from "../..";

export const calculateValues = (meal: Meal): Record<string, number> => {
    const map = {'ENERGY': 0.0, 'PROTEIN': 0.0, 'FAT': 0.0, 'CARBOHYDRATE': 0.0, 'FIBRE': 0.0}
    meal.foodstuffs!!.forEach(f => {
        map['ENERGY'] += (f.weight || 0) * (f.energyInKcal || 0)
        map['PROTEIN'] += (f.weight || 0) * (f.proteinMassFraction || 0)
        map['FAT'] += (f.weight || 0) * (f.fatMassFraction || 0)
        map['CARBOHYDRATE'] += (f.weight || 0) * (f.carbohydrateMassFraction || 0)
        map['FIBRE'] += (f.weight || 0) * (f.fibreMassFraction || 0)
    })
    return map
}