import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { BodyMeasurements } from "../..";

export function Bio() {

    let user = useSelector((state: RootState) => state.user)
    const currentBodyMeasurements = user.user?.bodyMeasurements?.find(bm => bm.type == BodyMeasurements.type.ACTUAL)!!

    return (
<Box p={5} style={{ background: '#EFE4E1', borderRadius: 10 }} sx={{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
}}>
    <Typography variant="h3" style={{ marginBottom: 10, color: '#582f0e', fontWeight: 'bold' }}>STRAVOVACÍ PLÁN</Typography>
    
    <Typography style={{ marginBottom: 15 }}>Vaha <strong>{currentBodyMeasurements.weight} kg</strong></Typography>
    <Typography style={{ marginBottom: 15 }}>Vyska <strong> {currentBodyMeasurements.height} cm</strong></Typography>
    <Typography style={{ marginBottom: 15 }}><strong> Miery</strong></Typography>

    <Typography style={{ marginBottom: 15 }}>Prsia <strong> {currentBodyMeasurements.chest} cm</strong></Typography>
    <Typography style={{ marginBottom: 15 }}>Pás <strong> {currentBodyMeasurements.waist} cm</strong></Typography>
    <Typography style={{ marginBottom: 15 }}>Boky <strong> {currentBodyMeasurements.hips} cm</strong></Typography>
    <Typography style={{ marginBottom: 15 }}>Zadok <strong> {currentBodyMeasurements.buttocks} cm</strong></Typography>
    <Typography style={{ marginBottom: 15 }}>Stehno <strong> {currentBodyMeasurements.thigh} cm</strong></Typography>
    <Typography style={{ marginBottom: 15 }}>Lýtko <strong> {currentBodyMeasurements.calf} cm</strong></Typography>
</Box>
    )
}