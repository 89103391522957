/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Foodstuff } from '../models/Foodstuff';
import type { FoodstuffsCalculateRequestDTO } from '../models/FoodstuffsCalculateRequestDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FoodstuffControllerService {

    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static createFoodstuff(
        requestBody: Foodstuff,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/foodstuffs',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns Foodstuff OK
     * @throws ApiError
     */
    public static calculateFoodstuff(
        requestBody: FoodstuffsCalculateRequestDTO,
    ): CancelablePromise<Array<Foodstuff>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/foodstuffs/calculate',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param like
     * @returns Foodstuff OK
     * @throws ApiError
     */
    public static getLikeFoodstuffs(
        like: string,
    ): CancelablePromise<Array<Foodstuff>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/foodstuffs/like/{like}',
            path: {
                'like': like,
            },
        });
    }

    /**
     * @param id
     * @returns Foodstuff OK
     * @throws ApiError
     */
    public static getFoodstuff(
        id: string,
    ): CancelablePromise<Foodstuff> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/foodstuffs/id/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Foodstuff OK
     * @throws ApiError
     */
    public static getAllFoodstuffs(): CancelablePromise<Array<Foodstuff>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/foodstuffs/all',
        });
    }

}
