/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { MeasurementUnit } from './MeasurementUnit';

export type Foodstuff = {
    id?: string;
    name?: string;
    languageCode?: Foodstuff.languageCode;
    density?: number;
    energyInKcal?: number;
    proteinMassFraction?: number;
    carbohydrateMassFraction?: number;
    fatMassFraction?: number;
    fibreMassFraction?: number;
    weight?: number;
    value?: number;
    locked?: boolean;
    allergens?: Array<'GLUTEN' | 'SHELLFISH' | 'EGGS' | 'FISH' | 'PEANUTS' | 'SOY' | 'MILK' | 'NUTS' | 'CELERY' | 'MUSTARD' | 'SESAME' | 'SULFITE' | 'LUPINUS' | 'MOLLUSCS'>;
    measurementUnits?: Array<MeasurementUnit>;
};

export namespace Foodstuff {

    export enum languageCode {
        EN = 'EN',
        CS = 'CS',
        SK = 'SK',
    }


}

