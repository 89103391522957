import { Card, CardContent, Typography } from "@mui/material"
import { m } from "vitest/dist/types-e3c9754d.js"

export function ConsumedMealDetail(props: any) {

    const { meal } = props

    return(
        <Card sx={{ minWidth: 275 }}>
        <CardContent>
          {meal.name}
        </CardContent>
      </Card>
    )

}